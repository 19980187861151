import React, { useState } from "react";

interface IResumenDeLaQueja {
  InformacionDelCliente: {
    Nombre: string;
    ApellidoPaterno: string;
    ApellidoMaterno: string;
    Estado: string;
    Municipio: string;
  };
  InformacionDeLaQueja: {
    Campaña: string;
    FechaDeQueja: string;
    TipoDeVozDelCliente: string;
    TipoDeServicio: string;
    Subservicio: string;
    CausaDeVozDelCliente: string;
    IncidenciaMayor: boolean;
    ClienteProblematico: boolean;
    ClienteVIP: boolean;
    ClienteSatisfecho: boolean;
    ProcedeQueja: boolean;
  };
}

const ResumenDeLaQueja = () => {
  const [showEstatusDeLaQueja, setShowEstatusDeLaQueja] = useState(true);
  const [showInfoDelCliente, setShowInfoDelCliente] = useState(true);
  const [showInfoQueja, setshowInfoQueja] = useState(true);
  const [showInfoSeguimiento, setshowInfoSeguimiento] = useState(true);
  const [data] = useState<IResumenDeLaQueja>({
    InformacionDelCliente: {
      Nombre: "Pablo",
      ApellidoPaterno: "Vázquez",
      ApellidoMaterno: "García",
      Estado: "Ciudad de México",
      Municipio: "Benito Juárez",
    },
    InformacionDeLaQueja: {
      Campaña: "Campaña",
      FechaDeQueja: "27-02-2021 10:50",
      TipoDeVozDelCliente: "Proveedores vial",
      TipoDeServicio: "Servicio",
      Subservicio: "Subservicio",
      CausaDeVozDelCliente: "Cobro injustificado",
      IncidenciaMayor: true,
      ClienteProblematico: true,
      ClienteVIP: true,
      ClienteSatisfecho: false,
      ProcedeQueja: true,
    },
  });

  return (
    <div className="flex flex-col gap-4">
      {/* SECTION Estatus de la queja */}
      <div className="py-4">
        <div className="flex flex-row justify-between text-xl font-bold text-gray-800">
          <div>
            <i className="pb-1 pr-2 bi bi-info-circle"></i>
            Estatus de la queja
          </div>
          <div className="text-xl rounded cursor-pointer hover:bg-indigo-100">
            <i
              className={`bi bi-chevron-${
                showEstatusDeLaQueja ? `contract` : `expand`
              }`}
              onClick={() => setShowEstatusDeLaQueja(!showEstatusDeLaQueja)}
            ></i>
          </div>
        </div>
        <hr className="bg-gray-300" />
      </div>

      {showEstatusDeLaQueja && (
        <div className="flex flex-col p-4">
          <div className="flex flex-row justify-between w-full">
            <p>Inicio de queja</p>
            <p>Retroalimentación</p>
            <p>Aplicación del pago</p>
            <p></p>
          </div>
          <div className="w-full">
            <input
              type="range"
              name="Severidad"
              id="Severidad"
              min="1"
              value="3"
              max="4"
              className="w-full cursor-pointer"
            />
          </div>
        </div>
      )}

      {/* SECTION Información del cliente */}
      <div className="pt-4">
        <div className="flex flex-row justify-between text-xl font-bold text-gray-800">
          <div>
            <i className="pb-1 pr-2 bi bi-person"></i>
            Información del cliente
          </div>
          <div className="text-xl rounded cursor-pointer hover:bg-indigo-100">
            <i
              className={`bi bi-chevron-${
                showInfoDelCliente ? `contract` : `expand`
              }`}
              onClick={() => setShowInfoDelCliente(!showInfoDelCliente)}
            ></i>
          </div>
        </div>
        <hr className="bg-gray-300" />
      </div>
      {showInfoDelCliente && (
        <div className="grid grid-cols-1 gap-2 md:gap-8 md:grid-cols-2">
          {/* SECTION First section */}
          <div className="flex flex-col gap-2">
            {/* SECTION Nombre */}
            <div className="flex flex-row justify-between">
              <p className="text-left text-gray-600">Nombre:</p>
              <p className="text-right text-gray-600">
                {data.InformacionDelCliente.Nombre}
              </p>
            </div>
            {/* !SECTION Nombre */}

            {/* SECTION ApellidoPaterno */}
            <div className="flex flex-row justify-between">
              <p className="text-left text-gray-600">Apellido Paterno:</p>
              <p className="text-right text-gray-600">
                {data.InformacionDelCliente.ApellidoPaterno}
              </p>
            </div>
            {/* !SECTION ApellidoPaterno */}

            {/* SECTION ApellidoMaterno */}
            <div className="flex flex-row justify-between">
              <p className="text-left text-gray-600">Apellido Materno:</p>
              <p className="text-right text-gray-600">
                {data.InformacionDelCliente.ApellidoMaterno}
              </p>
            </div>
            {/* !SECTION ApellidoMaterno */}
          </div>
          {/* !SECTION First section */}

          {/* SECTION First section */}
          <div className="flex flex-col gap-2">
            {/* SECTION Estado */}
            <div className="flex flex-row justify-between">
              <p className="text-left text-gray-600">Estado:</p>
              <p className="text-right text-gray-600">
                {data.InformacionDelCliente.Estado}
              </p>
            </div>
            {/* !SECTION Estado */}

            {/* SECTION Municipio */}
            <div className="flex flex-row justify-between">
              <p className="text-left text-gray-600">Municipio:</p>
              <p className="text-right text-gray-600">
                {data.InformacionDelCliente.Municipio}
              </p>
            </div>
            {/* !SECTION Municipio */}
          </div>
          {/* !SECTION First section */}
        </div>
      )}

      {/* SECTION Información de la queja */}
      <div className="pt-4">
        <div className="flex flex-row justify-between text-xl font-bold text-gray-800">
          <div>
            <i className="pb-1 pr-2 bi bi-megaphone"></i>
            Información de la queja
          </div>
          <div className="text-xl rounded cursor-pointer hover:bg-indigo-100">
            <i
              className={`bi bi-chevron-${
                showInfoQueja ? `contract` : `expand`
              }`}
              onClick={() => setshowInfoQueja(!showInfoQueja)}
            ></i>
          </div>
        </div>
        <hr className="bg-gray-300" />
      </div>
      {showInfoQueja && (
        <div className="grid grid-cols-1 gap-2 md:gap-8 md:grid-cols-2">
          {/* SECTION First section */}
          <div className="flex flex-col gap-2">
            {/* SECTION Campaña */}
            <div className="flex flex-row justify-between">
              <p className="text-left text-gray-600">Campaña:</p>
              <p className="text-right text-gray-600">
                {data.InformacionDeLaQueja.Campaña}
              </p>
            </div>
            {/* !SECTION Campaña */}

            {/* SECTION FechaDeQueja */}
            <div className="flex flex-row justify-between">
              <p className="text-left text-gray-600">Fecha de la queja:</p>
              <p className="text-right text-gray-600">
                {data.InformacionDeLaQueja.FechaDeQueja}
              </p>
            </div>
            {/* !SECTION FechaDeQueja */}

            {/* SECTION TipoDeVozDelCliente */}
            <div className="flex flex-row justify-between">
              <p className="text-left text-gray-600">
                Tipo de voz del cliente:
              </p>
              <p className="text-right text-gray-600">
                {data.InformacionDeLaQueja.TipoDeVozDelCliente}
              </p>
            </div>
            <div className="flex flex-row gap-8">
              <label
                htmlFor="ProcedeQueja"
                className="flex flex-row items-center gap-2 input-label"
              >
                <input
                  type="checkbox"
                  name="ProcedeQueja"
                  className="input"
                  disabled
                  checked={data.InformacionDeLaQueja.IncidenciaMayor}
                />
                Incidencia mayor
              </label>
              <label
                htmlFor="ProcedeQueja"
                className="flex flex-row items-center gap-2 input-label"
              >
                <input
                  type="checkbox"
                  name="ProcedeQueja"
                  className="input"
                  disabled
                  checked={data.InformacionDeLaQueja.ClienteProblematico}
                />
                Cliente problemático
              </label>
              <label
                htmlFor="ProcedeQueja"
                className="flex flex-row items-center gap-2 input-label"
              >
                <input
                  type="checkbox"
                  name="ProcedeQueja"
                  className="input"
                  disabled
                  checked={data.InformacionDeLaQueja.ClienteVIP}
                />
                Cliente VIP
              </label>
              <label
                htmlFor="ProcedeQueja"
                className="flex flex-row items-center gap-2 input-label"
              >
                <input
                  type="checkbox"
                  name="ProcedeQueja"
                  className="input"
                  disabled
                  checked={data.InformacionDeLaQueja.ClienteSatisfecho}
                />
                Cliente satisfecho
              </label>
              <label
                htmlFor="ProcedeQueja"
                className="flex flex-row items-center gap-2 input-label"
              >
                <input
                  type="checkbox"
                  name="ProcedeQueja"
                  className="input"
                  disabled
                  checked={data.InformacionDeLaQueja.ProcedeQueja}
                />
                Procede queja
              </label>
            </div>
            {/* !SECTION TipoDeVozDelCliente */}
          </div>
          {/* !SECTION First section */}

          {/* SECTION First section */}
          <div className="flex flex-col gap-2">
            {/* SECTION TipoDeServicio */}
            <div className="flex flex-row justify-between">
              <p className="text-left text-gray-600">Tipo de servicio:</p>
              <p className="text-right text-gray-600">
                {data.InformacionDeLaQueja.TipoDeServicio}
              </p>
            </div>
            {/* !SECTION Estado */}

            {/* SECTION Subservicio */}
            <div className="flex flex-row justify-between">
              <p className="text-left text-gray-600">Subservicio:</p>
              <p className="text-right text-gray-600">
                {data.InformacionDeLaQueja.Subservicio}
              </p>
            </div>

            {/* SECTION CausaDeVozDelCliente */}
            <div className="flex flex-row justify-between">
              <p className="text-left text-gray-600">
                Causa de voz del cliente:
              </p>
              <p className="text-right text-gray-600">
                {data.InformacionDeLaQueja.CausaDeVozDelCliente}
              </p>
            </div>

            {/* !SECTION CausaDeVozDelCliente */}
          </div>
          {/* !SECTION First section */}
        </div>
      )}

      {/* SECTION Información de la queja */}
      <div className="pt-4">
        <div className="flex flex-row justify-between text-xl font-bold text-gray-800">
          <div>
            <i className="pb-1 pr-2 bi bi-headset"></i>
            Seguimiento con el cliente
          </div>
          <div className="text-xl rounded cursor-pointer hover:bg-indigo-100">
            <i
              className={`bi bi-chevron-${
                showInfoSeguimiento ? `contract` : `expand`
              }`}
              onClick={() => setshowInfoSeguimiento(!showInfoSeguimiento)}
            ></i>
          </div>
        </div>
        <hr className="bg-gray-300" />
      </div>
      {showInfoSeguimiento && (
        <div className="grid grid-cols-1 gap-2 md:gap-8 md:grid-cols-2">
          {/* SECTION First section */}
          <div className="flex flex-col gap-2">
            {/* SECTION FechaDeQueja */}
            <div className="flex flex-row justify-between">
              <p className="text-left text-gray-600">
                Último seguimiento con el cliente:
              </p>
              <p className="text-right text-gray-600">
                {data.InformacionDeLaQueja.FechaDeQueja}
              </p>
            </div>
            {/* !SECTION FechaDeQueja */}

            {/* SECTION TipoDeVozDelCliente */}
            <div className="flex flex-row justify-between">
              <p className="text-left text-gray-600">Medio de contacto:</p>
              <p className="text-right text-gray-600">Correo</p>
            </div>
            {/* SECTION TipoDeVozDelCliente */}
            <div className="flex flex-row justify-between">
              <p className="text-left text-gray-600">Cierre con el cliente:</p>
              <p className="text-right text-gray-600">No</p>
            </div>

            {/* !SECTION TipoDeVozDelCliente */}
          </div>
          {/* !SECTION First section */}

          {/* SECTION First section */}
          <div className="flex flex-col gap-2">
            {/* SECTION TipoDeServicio */}
            <div className="flex flex-row justify-between">
              <p className="text-left text-gray-600">Se aplicó pago:</p>
              <p className="text-right text-gray-600">Sí</p>
            </div>
            {/* !SECTION Estado */}

            {/* SECTION FechaDeQueja */}
            <div className="flex flex-row justify-between">
              <p className="text-left text-gray-600">Fecha de aplicación:</p>
              <p className="text-right text-gray-600">
                {data.InformacionDeLaQueja.FechaDeQueja}
              </p>
            </div>
            {/* !SECTION FechaDeQueja */}
            {/* SECTION CausaDeVozDelCliente */}
            <div className="flex flex-row justify-between">
              <p className="text-left text-gray-600">Monto del pago:</p>
              <p className="text-right text-gray-600">2,500.00</p>
            </div>

            {/* !SECTION CausaDeVozDelCliente */}
          </div>
          {/* !SECTION First section */}
        </div>
      )}
    </div>
  );
};

export default ResumenDeLaQueja;
