import { DateTime, Interval } from "luxon";
import { useEffect, useState } from "react";

const SeguimientoConElCliente = (): JSX.Element => {
  const [showSeguimientos, setShowSeguimientos] = useState(true);
  const [fechaActual, setFechaActual] = useState(DateTime.now());
  const [proximoSeguimiento, setProximoSeguimiento] = useState(
    DateTime.now().plus({ days: 1 })
  );
  const [editarFecha, seteditarFecha] = useState(false);
  const [tiempoRestante, setTiempoRestante] = useState(
    Interval.fromDateTimes(fechaActual, proximoSeguimiento)
  );
  const [clienteContactado, setClienteContactado] = useState(false);

  useEffect(() => {
    setTiempoRestante(Interval.fromDateTimes(fechaActual, proximoSeguimiento));
  }, [proximoSeguimiento]);

  return (
    <div className="flex flex-col py-4 text-center align-bottom">
      <div className="flex flex-row justify-center gap-4 align-bottom">
        <i className="bi bi-bell"></i>
        <p>
          El próximo seguimiento toca en{" "}
          {editarFecha ? (
            <div>
              <input
                type="date"
                onChange={(e) => console.log(e.target.value)}
              />
              <input type="time" id="appt" name="appt" />
            </div>
          ) : (
            <p>{tiempoRestante.length() / 3600} horas</p>
          )}
        </p>
        <button
          className="px-2 button-primary"
          onClick={() => seteditarFecha(!editarFecha)}
        >
          {editarFecha ? "Guardar fecha" : "Editar fecha"}
        </button>
      </div>

      <div className="py-4">
        <div className="flex flex-row justify-between text-xl font-bold text-gray-800">
          <div>
            <i className="pb-1 pr-2 bi bi-clipboard-check"></i>
            Seguimientos
          </div>
          <div className="text-xl rounded cursor-pointer hover:bg-indigo-100">
            <i
              className={`bi bi-chevron-${
                showSeguimientos ? `contract` : `expand`
              }`}
              onClick={() => setShowSeguimientos(!showSeguimientos)}
            ></i>
          </div>
        </div>
        <hr className="bg-gray-300" />
      </div>

      {showSeguimientos && (
        <>
          <p className="pb-2 font-bold text-left">Seguimiento 1</p>
          {/* CHECK 1  */}
          <div className="flex flex-row gap-4">
            <label
              htmlFor="ProcedeQueja"
              className="flex flex-row items-center gap-4 input-label"
            >
              <input type="checkbox" name="ProcedeQueja" className="input" />
              Llamada
            </label>
            <p>
              DD/MM/AAAA <span className="pl-2">hh:mm:ss</span>
            </p>
          </div>
          {/* CHECK 1  */}
          <div className="flex flex-row gap-4">
            <label
              htmlFor="ProcedeQueja"
              className="flex flex-row items-center gap-4 input-label"
            >
              <input type="checkbox" name="ProcedeQueja" className="input" />
              Llamada
            </label>
            <p>
              DD/MM/AAAA <span className="pl-2">hh:mm:ss</span>
            </p>
          </div>
          {/* CHECK 1  */}
          <div className="flex flex-row gap-4">
            <label
              htmlFor="ProcedeQueja"
              className="flex flex-row items-center gap-4 input-label"
            >
              <input type="checkbox" name="ProcedeQueja" className="input" />
              Whatsapp
            </label>
            <p>
              DD/MM/AAAA <span className="pl-2">hh:mm:ss</span>
            </p>
          </div>
          {/* CHECK 1  */}
          <div className="flex flex-row gap-4">
            <label
              htmlFor="ProcedeQueja"
              className="flex flex-row items-center gap-4 input-label"
            >
              <input type="checkbox" name="ProcedeQueja" className="input" />
              Correo
            </label>
            <p>
              DD/MM/AAAA <span className="pl-2">hh:mm:ss</span>
            </p>
          </div>
          {/* CHECK 1  */}
          <div className="flex flex-row gap-4">
            <label
              htmlFor="ProcedeQueja"
              className="flex flex-row items-center gap-4 input-label"
            >
              <input type="checkbox" name="ProcedeQueja" className="input" />
              SMS
            </label>
            <p>
              DD/MM/AAAA <span className="pl-2">hh:mm:ss</span>
            </p>
          </div>
          <p className="pt-2 font-bold text-left">Resumen</p>
          <div className="flex flex-row gap-4">
            <label
              htmlFor="ProcedeQueja"
              className="flex flex-row items-center gap-4 input-label"
            >
              <input
                type="checkbox"
                name="ProcedeQueja"
                className="input"
                defaultChecked={clienteContactado}
                onChange={() => setClienteContactado(!clienteContactado)}
              />
              Contactado
            </label>

            {clienteContactado && (
              <label
                htmlFor="ProcedeQueja"
                className="flex flex-row items-center gap-4 input-label"
              >
                <input type="checkbox" name="ProcedeQueja" className="input" />
                Satisfecho
              </label>
            )}
          </div>
          <div className="text-center">
            <button className="px-2 button-primary">Añadir seguimiento</button>
          </div>
        </>
      )}
    </div>
  );
};

export default SeguimientoConElCliente;
