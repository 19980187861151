import React, { useState } from "react";
import { TiempoDelProveedor } from "../../helpers/colorizers";
import Chatbox from "../molecules/Chatbox";
import { MessageProps } from "../atoms/Message";
import { DateTime } from "luxon";

export interface BitacoraProps {
  Nueva?: boolean;
  UnidadDeNegocio: string;
  Area: string;
  TiempoDeRespuestaDelProveedor?: {
    FechaInicio: string | Date;
    FechaFin: string | Date;
    Porcentaje: number;
    TiempoRestante: string;
  };
  Messages: MessageProps[];
}

const Bitacora = ({
  UnidadDeNegocio,
  Area,
  TiempoDeRespuestaDelProveedor,
  Messages,
  Nueva = false,
}: BitacoraProps): JSX.Element => {
  const [showBitacora, setShowBitacora] = useState(true);
  const [slaProveedor] = useState<
    BitacoraProps["TiempoDeRespuestaDelProveedor"]
  >(TiempoDeRespuestaDelProveedor);
  const [newNombreProveedor, setNewNombreProveedor] = useState("");
  const [newArea, setNewArea] = useState("");
  const [UnidadesDeNegocio] = useState([
    "Club de asistencia",
    "Dental First",
    "911",
  ]);
  const [Areas] = useState([
    "Alta dirección",
    "Cuentas por pagar",
    "Privilegios PIF",
  ]);
  const [TiposDeRetroalimentacion] = useState([
    "Retroalimentación ejecutivo",
    "Retroalimentación supervisor",
    "Comentarios",
  ]);
  const [proveedorCerrado, setProveedorCerrado] = useState(false);

  const handleCierreProveedor = (): void => {
    alert(
      "¿Seguro que desea cerrar el proveedor? Esta acción no se puede revertir."
    );
    setProveedorCerrado(true);
  };
  const now = DateTime.now();
  const fecha = `${now.toLocaleString(DateTime.DATETIME_SHORT)}`;

  return (
    <div className="flex flex-col">
      <div className="sticky top-0 pt-4 bg-white">
        <div className="flex flex-row justify-between pb-2 text-xl font-bold text-gray-800">
          <div>
            <i className="pb-1 pr-2 bi bi-card-text"></i>
            {Nueva && "Nueva "}
            Bitácora de {Nueva ? newNombreProveedor : UnidadDeNegocio} -{" "}
            {Nueva ? newArea : Area}
          </div>
          <div className="text-xl rounded cursor-pointer hover:bg-indigo-100">
            <i
              className={`bi bi-chevron-${
                showBitacora ? `contract` : `expand`
              }`}
              onClick={() => setShowBitacora(!showBitacora)}
            ></i>
          </div>
        </div>
        <hr className="bg-gray-300" />
      </div>

      {/* SECTION Bitacora */}
      {showBitacora && (
        <div className="pt-4">
          {/* SECTION Configurar nuevo CASC */}
          <div className="grid grid-cols-4 gap-4 pb-4">
            <div className="flex flex-col items-stretch">
              <label
                htmlFor="UnidadDeNegocio"
                className="block mb-2 font-bold text-gray-700"
              >
                Unidad de negocio
              </label>
              <select
                name="UnidadDeNegocio"
                id="UnidadDeNegocio"
                className="px-4 py-2 input"
                onChange={(e) => setNewNombreProveedor(e.target.value)}
              >
                <option value="" selected disabled>
                  Selecciona
                </option>
                {UnidadesDeNegocio.map((unidad) => (
                  <option value={unidad}>{unidad}</option>
                ))}
              </select>
            </div>
            <div className="flex flex-col items-stretch">
              <label
                htmlFor="Area"
                className="block mb-2 font-bold text-gray-700"
              >
                Área
              </label>
              <select
                name="Area"
                id="Area"
                className="px-4 py-2 input"
                onChange={(e) => setNewArea(e.target.value)}
              >
                <option value="" selected disabled>
                  Selecciona
                </option>
                {Areas.map((area) => (
                  <option value={area}>{area}</option>
                ))}
              </select>
            </div>
            <div className="flex flex-col items-stretch">
              <label
                htmlFor="TipoRetroalimentacion"
                className="block mb-2 font-bold text-gray-700"
              >
                Tipo de retroalimentación que solicita
              </label>
              <select
                name="TipoRetroalimentacion"
                id="TipoRetroalimentacion"
                className="px-4 py-2 input"
              >
                <option value="" selected disabled>
                  Selecciona
                </option>
                {TiposDeRetroalimentacion.map((tipo) => (
                  <option value={tipo}>{tipo}</option>
                ))}
              </select>
            </div>
            <div className="flex flex-col items-stretch">
              <label
                htmlFor="TipoRetroalimentacion"
                className="block mb-2 font-bold text-gray-700"
              >
                Fecha de solicitud
              </label>
              <input
                type="text"
                name="FechaSolicitud"
                id="FechaSolicitud"
                className="input"
                defaultValue={fecha}
                readOnly={true}
              />
            </div>
          </div>
          {/* !SECTION Configurar nuevo CASC */}

          {/* SECTION Sla */}
          {!Nueva && (
            <div className="pt-2 pb-4 bg-white">
              <div className="text-center">
                <span className="font-bold">
                  Tiempo desde respuesta del proveedor
                </span>
              </div>
              <div className="flex flex-col">
                <div className="flex flex-row justify-between">
                  <p>{slaProveedor?.FechaInicio}</p>
                  <p>{slaProveedor?.FechaFin}</p>
                </div>
                <div className="relative">
                  <div className="flex h-2 my-2 overflow-hidden text-xs bg-gray-200 rounded">
                    <div
                      style={{
                        width: `${slaProveedor?.Porcentaje}%`,
                      }}
                      className={`flex flex-col justify-center text-center text-white rounded-full shadow-none whitespace-nowrap ${
                        slaProveedor?.Porcentaje &&
                        TiempoDelProveedor(slaProveedor?.Porcentaje)
                      }`}
                    ></div>
                  </div>
                </div>
                <div className="text-center">
                  <p>
                    Tiempo restante:{" "}
                    <span className="font-bold">
                      {slaProveedor?.TiempoRestante}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          )}
          {/* !SECTION Sla */}

          <h3 className="text-lg font-bold">Bitácora</h3>
          {/* SECTION Chat box */}
          <Chatbox Messages={Messages} Chatbar={!proveedorCerrado} />
          {/* !SECTION Chat box */}

          {/* SECTION Files */}
          {!proveedorCerrado && (
            <div className="grid items-stretch grid-cols-4 gap-4">
              {/* File 1 */}
              <div className="mx-auto my-8 overflow-hidden rounded-lg cursor-pointer">
                <div className="cursor-pointer md:flex">
                  <div className="w-full p-3">
                    <div className="relative flex items-center justify-center h-32 bg-gray-100 border-2 border-blue-700 border-dashed rounded-lg hover:bg-indigo-300">
                      <div className="absolute">
                        <div className="flex flex-col items-center">
                          <i className="text-3xl bi bi-image"></i>
                          <span className="block font-normal">
                            Añade imágenes.
                          </span>
                        </div>
                      </div>
                      <input
                        type="file"
                        className="w-full h-full opacity-0 cursor-pointer hover:bg-indigo-800"
                        name="Archivos"
                        multiple
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* File 2 */}
              <div className="max-w-md mx-auto my-8 overflow-hidden rounded-lg cursor-pointer md:max-w-xl">
                <div className="cursor-pointer md:flex">
                  <div className="w-full p-3">
                    <div className="relative flex items-center justify-center h-32 bg-gray-100 border-2 border-blue-700 border-dashed rounded-lg hover:bg-indigo-300">
                      <div className="absolute">
                        <div className="flex flex-col items-center">
                          <i className="text-3xl bi bi-play-btn"></i>
                          <span className="block font-normal">
                            Añade videos.
                          </span>
                        </div>
                      </div>
                      <input
                        type="file"
                        className="w-full h-full opacity-0 cursor-pointer hover:bg-indigo-800"
                        name="Archivos"
                        multiple
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* File 3 */}
              <div className="max-w-md mx-auto my-8 overflow-hidden rounded-lg cursor-pointer md:max-w-xl">
                <div className="cursor-pointer md:flex">
                  <div className="w-full p-3">
                    <div className="relative flex items-center justify-center h-32 bg-gray-100 border-2 border-blue-700 border-dashed rounded-lg hover:bg-indigo-300">
                      <div className="absolute">
                        <div className="flex flex-col items-center">
                          <i className="text-3xl bi bi-mic"></i>
                          <span className="block font-normal">
                            Añade archivos de audio.
                          </span>
                        </div>
                      </div>
                      <input
                        type="file"
                        className="w-full h-full opacity-0 cursor-pointer hover:bg-indigo-800"
                        name="Archivos"
                        multiple
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* File 4 */}
              <div className="max-w-md mx-auto my-8 overflow-hidden rounded-lg cursor-pointer md:max-w-xl">
                <div className="cursor-pointer md:flex">
                  <div className="w-full p-3">
                    <div className="relative flex items-center justify-center h-32 bg-gray-100 border-2 border-blue-700 border-dashed rounded-lg hover:bg-indigo-300">
                      <div className="absolute">
                        <div className="flex flex-col items-center">
                          <i className="text-3xl bi bi-files"></i>
                          <span className="block font-normal">
                            Añade PDF, Excel, etc.
                          </span>
                        </div>
                      </div>
                      <input
                        type="file"
                        className="w-full h-full opacity-0 cursor-pointer hover:bg-indigo-800"
                        name="Archivos"
                        multiple
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* !SECTION Files */}

          {/* SECTION Cierre del proveedor */}
          {proveedorCerrado ? (
            <div className="flex flex-col space-y-8">
              <h3 className="text-xl font-bold">Resumen</h3>

              {/* SECTION Forms */}
              <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
                <div className="flex flex-col items-stretch">
                  <label
                    htmlFor="CondicionesDelServicio"
                    className="block mb-2 font-bold text-gray-700"
                  >
                    Condiciones del servicio
                  </label>
                  <select
                    name="CondicionesDelServicio"
                    id="CondicionesDelServicio"
                    className="px-4 py-2 input"
                  >
                    <option value="" selected disabled>
                      Selecciona
                    </option>
                  </select>
                </div>

                <div className="flex flex-col items-stretch">
                  <label
                    htmlFor="ImputableClub"
                    className="block mb-2 font-bold text-gray-700"
                  >
                    Imputable Club
                  </label>
                  <select
                    name="ImputableClub"
                    id="ImputableClub"
                    className="px-4 py-2 input"
                  >
                    <option value="" selected disabled>
                      Selecciona
                    </option>
                  </select>
                </div>

                <div className="flex flex-col items-stretch">
                  <label
                    htmlFor="CausaClub"
                    className="block mb-2 font-bold text-gray-700"
                  >
                    Causa Club
                  </label>
                  <select
                    name="CausaClub"
                    id="CausaClub"
                    className="px-4 py-2 input"
                  >
                    <option value="" selected disabled>
                      Selecciona
                    </option>
                  </select>
                </div>

                <div className="flex flex-col items-stretch">
                  <label
                    htmlFor="ImputableCorportaivo"
                    className="block mb-2 font-bold text-gray-700"
                  >
                    Imputable corporativo
                  </label>
                  <select
                    name="ImputableCorportaivo"
                    id="ImputableCorportaivo"
                    className="px-4 py-2 input"
                  >
                    <option value="" selected disabled>
                      Selecciona
                    </option>
                  </select>
                </div>

                <div className="flex flex-col items-stretch">
                  <label
                    htmlFor="CausaCorporativo"
                    className="block mb-2 font-bold text-gray-700"
                  >
                    Causa corporativo
                  </label>
                  <select
                    name="CausaCorporativo"
                    id="CausaCorporativo"
                    className="px-4 py-2 input"
                  >
                    <option value="" selected disabled>
                      Selecciona
                    </option>
                  </select>
                </div>
              </div>
              {/* !SECTION Forms */}

              <div className="grid grid-cols-2 md:grid-cols-4">
                <label
                  htmlFor="IncidenciaMayor"
                  className="flex flex-row items-center gap-4 input-label"
                >
                  <input
                    type="checkbox"
                    name="IncidenciaMayor"
                    className="input"
                  />
                  Incidencia Mayor
                </label>
                <label
                  htmlFor="ProcedeQueja"
                  className="flex flex-row items-center gap-4 input-label"
                >
                  <input
                    type="checkbox"
                    name="ProcedeQueja"
                    className="input"
                  />
                  Procede queja
                </label>
                <label
                  htmlFor="ProcedePago"
                  className="flex flex-row items-center gap-4 input-label"
                >
                  <input type="checkbox" name="ProcedePago" className="input" />
                  Procede pago
                </label>
              </div>

              <div className="w-auto mx-auto text-center">
                <button className="px-4 button-primary">
                  Guardar Bitácora
                </button>
              </div>
            </div>
          ) : (
            <button
              className="px-4 button-primary"
              onClick={() => handleCierreProveedor()}
            >
              Cerrar proveedor
            </button>
          )}
          {/* SECTION Cierre del proveedor */}
        </div>
      )}
      {/* !SECTION Bitacora */}
    </div>
  );
};

export default Bitacora;
