import { useState } from "react";
import { Link } from "react-router-dom";
import Card from "src/components/molecules/Card";
import Searchbar from "src/components/molecules/SearchBar";
import { CardProps } from "src/components/molecules/Card";
type CardsProps = CardProps[];

const Tablero = (): JSX.Element => {
  const [cards] = useState<CardsProps>([
    {
      id: 1,
      title: "Información de la queja",
      titles: [
        "Queja",
        "Tipo de canal",
        "Tipo de servicio",
        "Tipo de incidencia",
        "Fecha de solicitud",
        "Fecha de reporte",
        "Severidad",
        "Etiquetas",
        "Asginado",
      ],
      content: [
        {
          CASC: "CASCAS0DA",
          Urgencia: 2,
          TipoDeCanal: "Dental First",
          TipoDeServicio: "Asistencia",
          TipoDeIncidencia: "Reembolsos",
          FechaDeSolicitud: "27 feb 2021",
          FechaDeReporte: "12 Mayo 2021",
          TiempoRestante: 50,
          Severidad: "Baja",
          Etiquetas: [
            { Nombre: "VIP", Color: "red-500" },
            { Nombre: "Problemático", Color: "green-500" },
          ],
          Asignado: "Juan Perez",
        },
        {
          CASC: "CASCAS0DA",
          Urgencia: 1,
          TipoDeCanal: "Dental First",
          TipoDeServicio: "Asistencia",
          TipoDeIncidencia: "Reembolsos",
          FechaDeSolicitud: "27 feb 2021",
          FechaDeReporte: "12 Mayo 2021",
          TiempoRestante: 50,
          Severidad: "Baja",
          Etiquetas: [{ Nombre: "Problemático", Color: "green-500" }],
          Asignado: "Juan Perez",
        },
        {
          CASC: "CASCAS0DA",
          Urgencia: 3,
          TipoDeCanal: "Dental First",
          TipoDeServicio: "Asistencia",
          TipoDeIncidencia: "Reembolsos",
          FechaDeSolicitud: "27 feb 2021",
          FechaDeReporte: "12 Mayo 2021",
          TiempoRestante: 50,
          Severidad: "Media",
          Etiquetas: [],
          Asignado: "Juan Perez",
        },
      ],
    },
    {
      id: 2,
      title: "Retroalimentación del proveedor",
      titles: [
        "Queja",
        "Tipo de canal",
        "Tipo de servicio",
        "Tipo de incidencia",
        "Fecha de solicitud",
        "Fecha de reporte",
        "Severidad",
        "Etiquetas",
        "Asginado",
      ],
      content: [
        {
          CASC: "CASCAS0DA",
          Urgencia: 1,
          TipoDeCanal: "Dental First",
          TipoDeServicio: "Asistencia",
          TipoDeIncidencia: "Reembolsos",
          FechaDeSolicitud: "27 feb 2021",
          FechaDeReporte: "12 Mayo 2021",
          TiempoRestante: 50,
          Severidad: "Alta",
          Etiquetas: [
            { Nombre: "VIP", Color: "red-500" },
            { Nombre: "Problemático", Color: "green-500" },
          ],
          Asignado: "Juan Perez",
        },
        {
          CASC: "CASCAS0DA",
          Urgencia: 2,
          TipoDeCanal: "Dental First",
          TipoDeServicio: "Asistencia",
          TipoDeIncidencia: "Reembolsos",
          FechaDeSolicitud: "27 feb 2021",
          FechaDeReporte: "12 Mayo 2021",
          TiempoRestante: 50,
          Severidad: "Baja",
          Etiquetas: [{ Nombre: "Problemático", Color: "green-500" }],
          Asignado: "Juan Perez",
        },
        {
          CASC: "CASCAS0DA",
          Urgencia: 3,
          TipoDeCanal: "Dental First",
          TipoDeServicio: "Asistencia",
          TipoDeIncidencia: "Reembolsos",
          FechaDeSolicitud: "27 feb 2021",
          FechaDeReporte: "12 Mayo 2021",
          TiempoRestante: 50,
          Severidad: "Media",
          Etiquetas: [],
          Asignado: "Juan Perez",
        },
      ],
    },
    {
      id: 3,
      title: "Retroalimentación con el proveedor",
      titles: [
        "Queja",
        "Tipo de canal",
        "Tipo de servicio",
        "Tipo de incidencia",
        "Fecha de solicitud",
        "Fecha de reporte",
        "Severidad",
        "Etiquetas",
        "Asginado",
      ],
      content: [
        {
          CASC: "CASCAS0DA",
          Urgencia: 2,
          TipoDeCanal: "Dental First",
          TipoDeServicio: "Asistencia",
          TipoDeIncidencia: "Reembolsos",
          FechaDeSolicitud: "27 feb 2021",
          FechaDeReporte: "12 Mayo 2021",
          TiempoRestante: 50,
          Severidad: "Alta",
          Etiquetas: [
            { Nombre: "VIP", Color: "red-500" },
            { Nombre: "Problemático", Color: "green-500" },
          ],
          Asignado: "Juan Perez",
        },
        {
          CASC: "CASCAS0DA",
          Urgencia: 2,
          TipoDeCanal: "Dental First",
          TipoDeServicio: "Asistencia",
          TipoDeIncidencia: "Reembolsos",
          FechaDeSolicitud: "27 feb 2021",
          FechaDeReporte: "12 Mayo 2021",
          TiempoRestante: 50,
          Severidad: "Baja",
          Etiquetas: [{ Nombre: "Problemático", Color: "green-500" }],
          Asignado: "Juan Perez",
        },
        {
          CASC: "CASCAS0DA",
          Urgencia: 1,
          TipoDeCanal: "Dental First",
          TipoDeServicio: "Asistencia",
          TipoDeIncidencia: "Reembolsos",
          FechaDeSolicitud: "27 feb 2021",
          FechaDeReporte: "12 Mayo 2021",
          TiempoRestante: 50,
          Severidad: "Media",
          Etiquetas: [],
          Asignado: "Juan Perez",
        },
      ],
    },
  ]);

  return (
    <div className="flex flex-col space-y-8">
      <Searchbar />
      {cards.map((card) => (
        <Card {...card} />
      ))}
      <Link
        className="fixed flex items-center justify-center w-12 h-12 mx-auto my-auto text-3xl font-extrabold text-center text-white transition-all bg-indigo-900 rounded-full shadow cursor-pointer bottom-4 right-4 focus:ring-0 focus:border-transparent hover:bg-indigo-700 md:w-20 md:h-20"
        to={`${process.env.PUBLIC_URL}/nuevo-casc`}
      >
        +
      </Link>
    </div>
  );
};

export default Tablero;
