import React, { useContext, useState } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import Loader from "./components/atoms/Loader";
import reportWebVitals from "./reportWebVitals";

const LoaderContext = React.createContext(false);
const LoaderUpdateContext = React.createContext<any>(null);

export function useLoader() {
  return [useContext(LoaderContext), useContext(LoaderUpdateContext)];
}

export const LoaderProvider = (props: any) => {
  const [loader, setLoader] = useState(false);
  return (
    <LoaderContext.Provider value={loader}>
      <LoaderUpdateContext.Provider value={setLoader}>
        <Loader loader={loader} />
        {props.children}
      </LoaderUpdateContext.Provider>
    </LoaderContext.Provider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <LoaderProvider>
      <App />
    </LoaderProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
