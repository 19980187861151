import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Api from "src/helpers/instances";
import useSWR, { mutate } from "swr";
import { Formik, Form, Field, ErrorMessage, validateYupSchema } from "formik";
import * as Yup from "yup";
import { useLoader } from "src/index";
import useRequest, { fetcher } from "src/helpers/fetcher";

interface Usuario {
  ID_Area: number;
  ID_Perfil: number;
  ID_Usuario: number;
  apMaterno: string;
  apPaterno: string;
  email: string;
  estatus: string;
  fotografia: null | string;
  nombre: string;
  usuario: string;
}

interface Perfil {
  ID_Perfil: number;
  nombre: string;
  estatus: string;
}

const AdministracionDeUsuarios = (): JSX.Element => {
  const [view, setView] = useState<number>(1);
  const [loader, setLoader] = useLoader();

  switch (view) {
    case 1:
      return (
        <ListaDeUsuarios
          setView={setView}
          loader={loader}
          setLoader={setLoader}
        />
      );

    case 2:
      return (
        <AnadirUsuario
          setView={setView}
          loader={loader}
          setLoader={setLoader}
        />
      );

    default:
      break;
  }

  return (
    <div className="flex flex-col w-full p-4 bg-white rounded shadow">
      <div className="pb-4 text-lg font-bold text-center">Usuarios</div>
    </div>
  );
};

const ListaDeUsuarios = (props: any) => {
  const [loader, setLoader] = useLoader();
  const { data: usuarios, error } = useSWR(`obtener_usuarios`, fetcher);

  const eliminarUsuario = (ID_Usuario: number) => {
    setLoader(true);
    Api.post(`eliminar_usuario`, { ID_Usuario })
      .then((res: any) => {
        mutate(`obtener_usuarios`);
      })
      .finally(() => setLoader(false));
  };

  usuarios ? setLoader(false) : setLoader(true);

  if (error)
    return (
      <React.Fragment>{toast.error(error.response.message)}</React.Fragment>
    );

  return (
    <div className="flex flex-col w-full bg-white rounded shadow">
      <div className="p-4">
        <div className="text-center">
          <div className="pb-4 text-lg font-bold">
            <i className="pr-4 bi bi-people"></i>
            Usuarios
          </div>
        </div>
        <div className="text-right">
          <button
            className="px-4 mb-4 button-primary"
            onClick={() => props.setView(2)}
          >
            Añadir usuario
          </button>
        </div>
        <div className="overflow-auto">
          <div className="table w-full shadow-sm table-auto mh-screen">
            <div className="table-header-group font-bold text-center text-gray-100 bg-indigo-900 border-b-2">
              <div className="table-cell p-2">ID</div>
              <div className="table-cell p-2">Nombre</div>
              <div className="table-cell p-2">Ap. Paterno</div>
              <div className="table-cell p-2">Ap. Materno</div>
              <div className="table-cell p-2">Email</div>
              <div className="table-cell p-2">Estatus</div>
              <div className="table-cell p-2">Usuario</div>
              <div className="table-cell p-2">Eliminar</div>
            </div>
            <div className="table-row-group divide-y">
              {usuarios?.map((user: Usuario) => (
                <div
                  className={`table-row text-center text-gray-800 align-middle divide-y group`}
                  key={user.ID_Usuario}
                >
                  <div className="table-cell p-2">{user.ID_Usuario}</div>
                  <div className="table-cell p-2">{user.nombre}</div>
                  <div className="table-cell p-2">{user.apPaterno}</div>
                  <div className="table-cell p-2">{user.apMaterno}</div>
                  <div className="table-cell p-2">{user.email}</div>
                  <div className="table-cell p-2">{user.estatus}</div>
                  <div className="table-cell p-2">{user.usuario}</div>
                  <div className="table-cell">
                    <button
                      className="px-4 text-white transition-all bg-red-500 rounded hover:bg-red-700"
                      onClick={() => eliminarUsuario(user.ID_Usuario)}
                    >
                      Eliminar
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const AnadirUsuario = (props: any) => {
  const [loader, setLoader] = useLoader();
  const { data, error } = useSWR("obtener_perfiles", fetcher);

  if (error) console.log(error);

  if (error) return <React.Fragment>{toast.error("Error")}</React.Fragment>;

  data ? props.setLoader(false) : props.setLoader(true);

  return (
    <div className="flex flex-col w-full p-4 bg-white rounded shadow">
      <div className="relative flex flex-row justify-center text-center">
        <div className="absolute top-0 left-0">
          <i
            className="cursor-pointer bi bi-arrow-left"
            onClick={() => props.setView(1)}
          ></i>
        </div>
        <div className="text-center">
          <div className="pb-4 text-lg font-bold text-center">
            <i className="pr-4 bi bi-people"></i>
            Añadir usuario
          </div>
        </div>
      </div>

      <Formik
        initialValues={{
          ID_Perfil: "",
          estatus: "",
          email: "",
          usuario: "",
          contrasena: "",
          nombre: "",
          apPaterno: "",
          apMaterno: "",
        }}
        validationSchema={Yup.object({
          nombre: Yup.string().required("Campo obligatorio."),
          apPaterno: Yup.string().required("Campo obligatorio."),
          apMaterno: Yup.string().required("Campo obligatorio."),
          email: Yup.string()
            .email("Dirección de correo inválida.")
            .required("Campo obligatorio."),
          usuario: Yup.string()
            .email("Dirección de correo inválida.")
            .required("Campo obligatorio."),
          contrasena: Yup.string()
            .required("Campo obligatorio.")
            .matches(
              /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
              "La contraseña debe contener 8 caracteres, 1 mayúscula y 1 minúscula, 1 número y 1 caracter especial."
            ),
        })}
        onSubmit={(values, { setSubmitting }) => {
          values.estatus = "Activo";

          setSubmitting(false);
          setLoader(true);
          Api.post(`crear_usuario`, values)
            .then((res: any) =>
              res.success === 1
                ? (toast.success("Usuario creado exitosamente"),
                  props.setView(1))
                : toast.error(res.message)
            )
            .finally(() => setLoader(false));
        }}
      >
        {({ values }) => (
          // Tipo de usuario
          <Form className="flex flex-col w-full">
            <div className="flex flex-col items-stretch">
              <label
                htmlFor="ID_Perfil"
                className="block mb-2 font-bold text-gray-700"
              >
                Tipo de usuario
              </label>
              <Field as="select" name="ID_Perfil" className="px-4 py-2 input">
                <option value="" disabled selected={true}>
                  Selecciona
                </option>
                {data?.map((perfil: Perfil) => (
                  <option value={perfil.ID_Perfil} key={perfil.ID_Perfil}>
                    {perfil.nombre}
                  </option>
                ))}
              </Field>
              <span className="mb-4 text-red-500">
                <ErrorMessage name="ID_Perfil" className="text-7xl" />
              </span>
            </div>
            {/* Nombre */}
            <div className="flex flex-col items-stretch">
              <label
                htmlFor="Nombre"
                className="block mb-2 font-bold text-gray-700"
              >
                Nombre(s)
              </label>
              <Field
                name="nombre"
                type="text"
                placeholder="Llenar"
                className="input"
              />
              <span className="mb-4 text-red-500">
                <ErrorMessage name="nombre" className="text-7xl" />
              </span>
            </div>
            {/* ApellidoPaterno */}
            <div className="flex flex-col items-stretch">
              <label
                htmlFor="apPaterno"
                className="block mb-2 font-bold text-gray-700"
              >
                Apellido paterno
              </label>
              <Field
                name="apPaterno"
                type="text"
                placeholder="Llenar"
                className="input"
              />
              <span className="mb-4 text-red-500">
                <ErrorMessage name="apPaterno" className="text-7xl" />
              </span>
            </div>
            {/* ApellidoMaterno */}
            <div className="flex flex-col items-stretch">
              <label
                htmlFor="apMaterno"
                className="block mb-2 font-bold text-gray-700"
              >
                Apellido materno
              </label>
              <Field
                name="apMaterno"
                type="text"
                placeholder="Llenar"
                className=" input"
              />
              <span className="mb-4 text-red-500">
                <ErrorMessage name="apMaterno" className="text-7xl" />
              </span>
            </div>
            {/* Email */}
            <div className="flex flex-col items-stretch">
              <label
                htmlFor="email"
                className="block mb-2 font-bold text-gray-700"
              >
                Email
              </label>
              <Field
                name="email"
                type="email"
                placeholder="Llenar"
                className=" input"
              />
              <span className="mb-4 text-red-500">
                <ErrorMessage name="email" className="text-7xl" />
              </span>
            </div>
            {/* usuario */}
            <div className="flex flex-col items-stretch">
              <label
                htmlFor="usuario"
                className="block mb-2 font-bold text-gray-700"
              >
                Usuario
              </label>
              <Field
                name="usuario"
                type="usuario"
                placeholder="Llenar"
                defaultValue={values.email}
                className="p-2 pl-4 input"
              />
              <span className="mb-4 text-red-500">
                <ErrorMessage name="usuario" className="text-7xl" />
              </span>
            </div>
            {/* Password */}
            <div className="flex flex-col items-stretch">
              <label
                htmlFor="contrasena"
                className="block mb-2 font-bold text-gray-700"
              >
                Contraseña
              </label>
              <Field
                name="contrasena"
                type="password"
                placeholder="Llenar"
                className="input"
              />
              <span className="mb-4 text-red-500">
                <ErrorMessage name="contrasena" className="text-7xl" />
              </span>
            </div>
            <div className="flex flex-col items-stretch mt-4 text-left">
              <button className="items-stretch button-primary">
                Crear usuario
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AdministracionDeUsuarios;
