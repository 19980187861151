import React, { useState } from "react";
import Bitacora, { BitacoraProps } from "src/components/casc/Bitacora";
import { MessageProps } from "src/components/atoms/Message";
import Chatbox from "src/components/molecules/Chatbox";
type Bitacoras = BitacoraProps[];
type Messages = MessageProps[];

const RetroalimentacionDelProveedor = (): JSX.Element => {
  const [showCliente, setShowCliente] = useState(true);
  const [bitacoras, setBitacoras] = useState<Bitacoras>([
    {
      UnidadDeNegocio: "Club de asistencia",
      Area: "Mesa de control",
      TiempoDeRespuestaDelProveedor: {
        FechaInicio: "23/04/2021",
        FechaFin: "24/04/2021",
        Porcentaje: 50,
        TiempoRestante: "1 día",
      },
      Messages: [
        {
          Recibido: false,
          Nombre: "Michelle Rodríguez",
          Mensaje:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam et delectus impedit. Vel necessitatibus libero unde cumque a sed? Alias accusamus natus vero rerum recusandae sed eum veniam aliquid assumenda.",
          Fecha: "23/04/2021 10:50 a.m.",
        },
        {
          Recibido: true,
          Nombre: "Proveedor 1",
          Mensaje:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam et delectus impedit. Vel necessitatibus libero unde cumque a sed? Alias accusamus natus vero rerum recusandae sed eum veniam aliquid assumenda.",
          Fecha: "23/04/2021 10:52 a.m.",
        },
        {
          Recibido: false,
          Nombre: "Tú",
          Mensaje:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam et delectus impedit. Vel necessitatibus libero unde cumque a sed? Alias accusamus natus vero rerum recusandae sed eum veniam aliquid assumenda.",
          Fecha: "23/04/2021 11:00 a.m.",
        },
        {
          Recibido: false,
          Nombre: "Tú",
          Mensaje: "¡De acuerdo!",
          Fecha: "23/04/2021 11:10 a.m.",
        },
      ],
    },
    {
      UnidadDeNegocio: "Corporativo",
      Area: "Corporativo",
      TiempoDeRespuestaDelProveedor: {
        FechaInicio: "23/04/2021",
        FechaFin: "24/04/2021",
        Porcentaje: 50,
        TiempoRestante: "1 día",
      },
      Messages: [
        {
          Recibido: false,
          Nombre: "Michelle Rodríguez",
          Mensaje:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam et delectus impedit. Vel necessitatibus libero unde cumque a sed? Alias accusamus natus vero rerum recusandae sed eum veniam aliquid assumenda.",
          Fecha: "23/04/2021 10:50 a.m.",
        },
        {
          Recibido: true,
          Nombre: "Proveedor 1",
          Mensaje:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam et delectus impedit. Vel necessitatibus libero unde cumque a sed? Alias accusamus natus vero rerum recusandae sed eum veniam aliquid assumenda.",
          Fecha: "23/04/2021 10:52 a.m.",
        },
        {
          Recibido: false,
          Nombre: "Tú",
          Mensaje:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam et delectus impedit. Vel necessitatibus libero unde cumque a sed? Alias accusamus natus vero rerum recusandae sed eum veniam aliquid assumenda.",
          Fecha: "23/04/2021 11:00 a.m.",
        },
        {
          Recibido: false,
          Nombre: "Tú",
          Mensaje: "¡De acuerdo!",
          Fecha: "23/04/2021 11:10 a.m.",
        },
      ],
    },
  ]);
  const [quejaDelCliente] = useState<Messages>([
    {
      Recibido: true,
      Nombre: "Nombre del cliente",
      Mensaje: "Estoy enojado.",
    },
    {
      Recibido: true,
      Nombre: "Nombre del cliente",
      Mensaje: "No me atendieron correctamente.",
    },
    {
      Recibido: true,
      Nombre: "Nombre del cliente",
      Mensaje: "Quiero un reembolso.",
    },
    {
      Recibido: true,
      Nombre: "Nombre del cliente",
      Mensaje:
        "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Necessitatibus dicta voluptatum distinctio quia nihil perspiciatis blanditiis adipisci placeat, exercitationem esse voluptatem fuga quae minima nobis recusandae veritatis corrupti voluptate maxime? Lorem ipsum dolor, sit amet consectetur adipisicing elit. Autem molestiae ipsa suscipit quis fugit, illo doloribus reprehenderit repellendus sunt dolore minus dolores et, facilis veritatis aspernatur? Quo sunt officiis odio Nisi voluptate consectetur eos veritatis nostrum harum sequi unde? Dignissimos incidunt dolore quidem corporis repudiandae temporibus velit, rerum est, delectus voluptatibus consectetur sequi eveniet minus in quo ipsum, dolorum iste. Rerum, ad iure ipsum, eius sunt dolor, dolores nam soluta suscipit maiores unde illo atque vel quibusdam repudiandae pariatur. Ad voluptates quisquam temporibus laborum saepe voluptatem porro veritatis iure officia? Obcaecati debitis dignissimos repellat ipsum illo impedit. Voluptatum minus doloribus perferendis, autem vitae repellendus velit quas deleniti perspiciatis ullam provident praesentium quisquam. Dolor eum corrupti, totam accusantium quis minus facere. Molestiae recusandae quasi id expedita magni deserunt iste, ut modi dolorem non, aliquid debitis saepe esse porro ratione minima suscipit voluptatibus vero doloribus ea animi quibusdam autem in optio! Cupiditate. Architecto expedita natus ad voluptates eaque accusantium blanditiis ipsam, debitis maiores porro, ea possimus beatae dignissimos aspernatur hic quaerat maxime fuga fugiat pariatur nostrum! Illum, sequi vel? Quaerat, aliquam deserunt. Ab odio quo nulla blanditiis, voluptatibus repudiandae voluptate! Nam quis maxime quia illum? Explicabo cupiditate, animi temporibus corrupti adipisci magnam veniam, vitae consequuntur at dolore saepe. Provident repellendus numquam quae?",
    },
  ]);

  return (
    <div className="flex flex-col space-y-8">
      {/* SECTION Queja del cliente */}
      <React.Fragment>
        <div className="sticky top-0 pt-4 bg-white">
          <div className="flex flex-row justify-between pb-2 text-xl font-bold text-gray-800">
            <div>
              <i className="pb-1 pr-2 bi bi-megaphone"></i>
              Queja del cliente
            </div>
            <div className="text-xl rounded cursor-pointer hover:bg-indigo-100">
              <i
                className={`bi bi-chevron-${
                  showCliente ? `contract` : `expand`
                }`}
                onClick={() => setShowCliente(!showCliente)}
              ></i>
            </div>
          </div>
          <hr className="bg-gray-300" />
        </div>
        {showCliente && <Chatbox Messages={quejaDelCliente} Chatbar={false} />}
      </React.Fragment>
      {/* !SECTION Queja del cliente */}

      {/* SECTION Bitácora */}
      {bitacoras.map((bitacora, i) => (
        <Bitacora {...bitacora} key={i} />
      ))}
      {/* !SECTION Bitácora */}

      <div className="flex flex-row pt-16 justify-evenly">
        <button
          className="px-4 button-primary"
          onClick={() =>
            setBitacoras([
              ...bitacoras,
              { Nueva: true, UnidadDeNegocio: "", Area: "", Messages: [] },
            ])
          }
        >
          Añadir nueva bitácora
        </button>
        <button className="px-4 button-primary">Guardar cambios</button>
      </div>
    </div>
  );
};

export default RetroalimentacionDelProveedor;
