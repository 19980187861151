import axios from "axios";

const api = axios.create({
  baseURL: "http://146.148.79.211/calidad_backend/api/",
});

api.interceptors.request.use((req) => {
  const token = localStorage.getItem("token");
  req.headers.Authorization = token;
  return req;
});

api.interceptors.response.use((res) => {
  if (res.data.success === 0 && res.data.message === "Session expirada") {
    localStorage.clear();
    window.location.href = `${process.env.PUBLIC_URL}/login`;
  }
  // Loggeamos la respuesta para hacer debug
  console.log(`${res.config.url}`);
  console.log(res.data);
  console.groupEnd();

  return res.data;
});

export default api;
