import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
// Import Swiper styles
import * as Yup from "yup";
// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import DefaultImage from "src/assets/images/defaultimg.jpg";
// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";

export interface InformacionDelFormProps {
  Nombres?: string;
  ApellidoPaterno?: string;
  ApellidoMaterno?: string;
  Telefono?: string | number;
  Celular?: string | number;
  Correo?: string;
  Cuenta?: string;
  Estado?: string;
  Municipio?: string;
  Campaña?: string;
  Severidad?: 1 | 2 | 3;
  EjecutivoDeCalidad?: string;
  ProcedeQueja?: boolean;
  ClienteProblematico?: boolean;
  IncidenciaMayor?: boolean;
  ClienteVIP?: boolean;
  CASExistente?: boolean;
}

interface InformacionDeLaQuejaProps {
  FormDataProps?: InformacionDelFormProps;
  ReadOnly?: boolean;
}

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

const InformacionDeLaQueja = ({
  FormDataProps,
  ReadOnly = false,
}: InformacionDeLaQuejaProps): JSX.Element => {
  const [showInfoCliente, setShowInfoCliente] = useState<boolean>(true);
  const [showInfoQueja, setShowInfoQueja] = useState<boolean>(true);
  const [showSeveridad, setShowSeveridad] = useState<boolean>(true);
  const [showInfoCASC, setShowInfoCASC] = useState<boolean>(true);
  const [readOnly] = useState<boolean>(ReadOnly);
  const ErrorMessages = {
    required: "Campo obligatorio.",
    phone: "El número debe de contener diez dígitos.",
    notNumber: "Sólo se admiten números.",
    email: "Dirección de correo inválida",
  };
  return (
    <Formik
      initialValues={{
        ...FormDataProps,
      }}
      validationSchema={Yup.object({
        Nombres: Yup.string().required(ErrorMessages.required),
        ApellidoPaterno: Yup.string().required(ErrorMessages.required),
        ApellidoMaterno: Yup.string().required(ErrorMessages.required),
        Telefono: Yup.string()
          .required(ErrorMessages.required)
          .length(10, ErrorMessages.phone)
          .matches(/^\d+$/, ErrorMessages.notNumber),
        Celular: Yup.string()
          .required(ErrorMessages.required)
          .length(10, ErrorMessages.phone)
          .matches(/^\d+$/, ErrorMessages.notNumber),
        Correo: Yup.string()
          .required(ErrorMessages.required)
          .email(ErrorMessages.email),
        Cuenta: Yup.string().required(ErrorMessages.required),
        Estado: Yup.string().required(ErrorMessages.required),
        Municipio: Yup.string().required(ErrorMessages.required),
        Campaña: Yup.string().required(ErrorMessages.required),
        Severidad: Yup.number().required(ErrorMessages.required),
      })}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
      }}
    >
      {({ values }) => (
        <Form className="flex flex-col">
          <div>
            <Swiper
              spaceBetween={10}
              slidesPerView={3}
              navigation
              pagination={{ clickable: true }}
              scrollbar={{ draggable: true }}
              className={`w-min max-w-lg ${!readOnly && `hidden`}`}
            >
              <SwiperSlide>
                <img src={DefaultImage} className="w-40" alt="1" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={DefaultImage} alt="1" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={DefaultImage} alt="1" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={DefaultImage} alt="1" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={DefaultImage} alt="1" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={DefaultImage} alt="1" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={DefaultImage} alt="1" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={DefaultImage} alt="1" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={DefaultImage} alt="1" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={DefaultImage} alt="1" />
              </SwiperSlide>
            </Swiper>
            {/* SECTION Info CASC */}
            {!readOnly && (
              <React.Fragment>
                <div className="py-4">
                  <div className="flex flex-row justify-between text-xl font-bold text-gray-800">
                    <div>
                      <i className="pb-1 pr-2 bi bi-card-text"></i>
                      Información del CASC
                    </div>
                    <div className="text-xl rounded cursor-pointer hover:bg-indigo-100">
                      <i
                        className={`bi bi-chevron-${
                          showInfoCASC ? `contract` : `expand`
                        }`}
                        onClick={() => setShowInfoCASC(!showInfoCASC)}
                      ></i>
                    </div>
                  </div>

                  <hr className="bg-gray-300" />
                </div>
                <div className="flex flex-col space-y-4">
                  <div className="flex flex-row">
                    <div className="relative inline-block w-10 mr-2 align-middle transition duration-200 ease-in select-none">
                      <Field
                        type="checkbox"
                        name="CASExistente"
                        id="CASExistente"
                        className="absolute block w-6 h-6 bg-white border-4 rounded-full appearance-none cursor-pointer toggle-checkbox"
                        readOnly={readOnly}
                      />
                      <label
                        htmlFor="CASExistente"
                        className="block h-6 overflow-hidden bg-gray-200 rounded-full shadow cursor-pointer toggle-label"
                      ></label>
                    </div>
                    <p>CAS Existente</p>
                  </div>
                  {values.CASExistente && (
                    <div className="flex flex-row">
                      <div className="relative inline-block w-10 mr-2 align-middle transition duration-200 ease-in select-none">
                        <Field
                          type="checkbox"
                          name="CASCCerrado"
                          id="CASCCerrado"
                          className="absolute block w-6 h-6 bg-white border-4 rounded-full appearance-none cursor-pointer toggle-checkbox"
                        />
                        <label
                          htmlFor="CASCCerrado"
                          className="block h-6 overflow-hidden bg-gray-200 rounded-full shadow cursor-pointer toggle-label"
                        ></label>
                      </div>
                      <p>Proviene de un CASC ya cerrado</p>
                    </div>
                  )}
                </div>
                {values.CASExistente && (
                  <div className="relative col-span-10 py-4">
                    <input
                      type="text"
                      name="Search"
                      id="search"
                      className="w-full h-full px-4 input md:px-12"
                      placeholder="Buscar CAS"
                    />
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <i className="hidden text-gray-300 fill-current md:block bi bi-search md:text-2xl"></i>
                    </div>
                  </div>
                )}
              </React.Fragment>
            )}
            {/* !SECTION Info CASC */}

            {/* SECTION Información del cliente  */}
            <div className="py-4">
              <div className="flex flex-row justify-between text-xl font-bold text-gray-800">
                <div>
                  <i className="pb-1 pr-2 bi bi-person-fill"></i>
                  Información del cliente
                </div>
                <div className="text-xl rounded cursor-pointer hover:bg-indigo-100">
                  <i
                    className={`bi bi-chevron-${
                      showInfoCliente ? `contract` : `expand`
                    }`}
                    onClick={() => setShowInfoCliente(!showInfoCliente)}
                  ></i>
                </div>
              </div>

              <hr className="bg-gray-300" />
            </div>
            {showInfoCliente && (
              <div className="grid items-stretch grid-cols-1 gap-x-8 gap-y-4 md:grid-cols-2 lg:grid-cols-3">
                {/* SECTION Nombre */}
                <div className="flex flex-col items-stretch">
                  <label
                    htmlFor="Nombres"
                    className="block mb-2 font-bold text-gray-700"
                  >
                    Nombre(s)
                  </label>
                  <Field
                    name="Nombres"
                    type="text"
                    placeholder="Llenar"
                    className="px-4 py-2 input"
                    readOnly={readOnly}
                  />
                  <span className="mb-4 text-red-500">
                    <ErrorMessage name="Nombres" className="text-7xl" />
                  </span>
                </div>
                {/* !SECTION Nombre */}

                {/* SECTION Apellido Paterno  */}
                <div className="flex flex-col items-stretch">
                  <label
                    htmlFor="ApellidoPaterno"
                    className="block mb-2 font-bold text-gray-700"
                  >
                    Apellido Paterno
                  </label>
                  <Field
                    name="ApellidoPaterno"
                    type="text"
                    placeholder="Llenar"
                    className="px-4 py-2 input"
                    readOnly={readOnly}
                  />
                  <span className="mb-4 text-red-500">
                    <ErrorMessage name="ApellidoPaterno" className="text-7xl" />
                  </span>
                </div>
                {/* !SECTION Apellido Paterno  */}

                {/* SECTION Apellido Materno */}
                <div className="flex flex-col items-stretch">
                  <label
                    htmlFor="ApellidoMaterno"
                    className="block mb-2 font-bold text-gray-700"
                  >
                    Apellido Materno
                  </label>
                  <Field
                    name="ApellidoMaterno"
                    type="text"
                    placeholder="Llenar"
                    className="px-4 py-2 input"
                    readOnly={readOnly}
                  />
                  <span className="mb-4 text-red-500">
                    <ErrorMessage name="ApellidoMaterno" className="text-7xl" />
                  </span>
                </div>
                {/* !SECTION Apellido Materno */}

                {/* SECTION Teléfono de casa  */}

                <div className="flex flex-col items-stretch">
                  <label
                    htmlFor="Telefono"
                    className="block mb-2 font-bold text-gray-700"
                  >
                    Teléfono:
                  </label>
                  <Field
                    name="Telefono"
                    type="text"
                    placeholder="Llenar"
                    className="px-4 py-2 input"
                    readOnly={readOnly}
                  />
                  <span className="mb-4 text-red-500">
                    <ErrorMessage name="Telefono" className="text-7xl" />
                  </span>
                </div>
                {/* !SECTION Teléfono de casa  */}

                {/*  SECTION Celular */}
                <div className="flex flex-col items-stretch">
                  <label
                    htmlFor="Celular"
                    className="block mb-2 font-bold text-gray-700"
                  >
                    Celular
                  </label>
                  <Field
                    name="Celular"
                    type="tel"
                    placeholder="Llenar"
                    className="px-4 py-2 input"
                    readOnly={readOnly}
                  />
                  <span className="mb-4 text-red-500">
                    <ErrorMessage name="Celular" className="text-7xl" />
                  </span>
                </div>
                {/*  !SECTION Celular */}

                {/* SECTION Correo */}
                <div className="flex flex-col items-stretch">
                  <label
                    htmlFor="Correo"
                    className="block mb-2 font-bold text-gray-700"
                  >
                    Correo Electrónico
                  </label>
                  <Field
                    name="Correo"
                    type="mail"
                    placeholder="Llenar"
                    className="px-4 py-2 input"
                    readOnly={readOnly}
                  />
                  <span className="mb-4 text-red-500">
                    <ErrorMessage name="Correo" className="text-7xl" />
                  </span>
                </div>
                {/* !SECTION Correo */}

                {/* SECTION Cuenta */}
                <div className="flex flex-col items-stretch">
                  <label
                    htmlFor="Cuenta"
                    className="block mb-2 font-bold text-gray-700"
                  >
                    Cuenta
                  </label>
                  <Field
                    name="Cuenta"
                    type="text"
                    placeholder="Llenar"
                    className="px-4 py-2 input"
                    readOnly={readOnly}
                  />
                  <span className="mb-4 text-red-500">
                    <ErrorMessage name="Cuenta" className="text-7xl" />
                  </span>
                </div>
                {/* !SECTION Cuenta */}

                {/* SECTION Estado */}
                <div className="flex flex-col items-stretch">
                  <label
                    htmlFor="Estado"
                    className="block mb-2 font-bold text-gray-700"
                  >
                    Estado
                  </label>
                  <Field
                    as="select"
                    name="Estado"
                    className="px-4 py-2 input"
                    readOnly={readOnly}
                  >
                    <option value="" disabled selected={true}>
                      Selecciona
                    </option>
                    <option value="CDMX">CDMX</option>
                    <option value="Aguascalientes">Aguascalientes</option>
                  </Field>
                  <span className="mb-4 text-red-500">
                    <ErrorMessage name="Estado" className="text-7xl" />
                  </span>
                </div>
                {/* !SECTION Estado */}

                {/* SECTION municipio */}
                <div className="flex flex-col items-stretch">
                  <label
                    htmlFor="Municipio"
                    className="block mb-2 font-bold text-gray-700"
                  >
                    Municipio
                  </label>
                  <Field
                    as="select"
                    name="Municipio"
                    className="px-4 py-2 input"
                    readOnly={readOnly}
                  >
                    <option value="" disabled selected={true}>
                      Selecciona
                    </option>
                    <option value="CDMX">CDMX</option>
                    <option value="otro">Otro</option>
                  </Field>
                  <span className="mb-4 text-red-500">
                    <ErrorMessage name="Municipio" className="text-7xl" />
                  </span>
                </div>
                {/* !SECTION municipio */}

                {/* OtroMunicipio */}
                {values.Municipio === "otro" && (
                  <div className="flex flex-col items-stretch">
                    <label
                      htmlFor="OtroMunicipio"
                      className="block mb-2 font-bold text-gray-700"
                    >
                      Otro Municipio
                    </label>
                    <Field
                      name="OtroMunicipio"
                      type="text"
                      placeholder="Llenar"
                      className="px-4 py-2 input"
                      readOnly={readOnly}
                    />
                    <span className="mb-4 text-red-500">
                      <ErrorMessage name="OtroMunicipio" className="text-7xl" />
                    </span>
                  </div>
                )}
              </div>
            )}
            {/* !SECTION Información del cliente  */}

            {/* SECTION Severidad de la queja */}
            <div className="py-4">
              <div className="flex flex-row justify-between text-xl font-bold text-gray-800">
                <div>
                  <i className="pb-1 pr-2 bi bi-exclamation-circle"></i>
                  Severidad de la queja
                </div>
                <div className="text-xl rounded cursor-pointer hover:bg-indigo-100">
                  <i
                    className={`bi bi-chevron-${
                      showSeveridad ? `contract` : `expand`
                    }`}
                    onClick={() => setShowSeveridad(!showSeveridad)}
                  ></i>
                </div>
              </div>

              <hr className="bg-gray-300" />
            </div>

            {showSeveridad && (
              <div className="flex flex-col p-4">
                <div className="flex flex-row justify-between w-full">
                  <p>Baja</p>
                  <p>Media</p>
                  <p>Alta</p>
                </div>
                <div className="w-full">
                  <Field
                    type="range"
                    name="Severidad"
                    id="Severidad"
                    min="1"
                    max="3"
                    className="w-full cursor-pointer"
                  />
                </div>
              </div>
            )}
            {/* !SECTION Severidad de la queja */}

            {/* SECTION Información de la queja  */}
            <div className="py-4">
              <div className="flex flex-row justify-between text-xl font-bold text-gray-800">
                <div>
                  <i className="pb-1 pr-2 bi bi-receipt"></i>
                  Información de la queja
                </div>
                <div className="text-xl rounded cursor-pointer hover:bg-indigo-100">
                  <i
                    className={`bi bi-chevron-${
                      showInfoQueja ? `contract` : `expand`
                    }`}
                    onClick={() => setShowInfoQueja(!showInfoQueja)}
                  ></i>
                </div>
              </div>
              <hr className="bg-gray-300" />
            </div>
            {showInfoQueja && (
              <>
                <div className="grid items-stretch grid-cols-1 gap-x-8 gap-y-4 md:grid-cols-2 lg:grid-cols-3">
                  {/* SECTION Campaña */}
                  <div className="flex flex-col items-stretch">
                    <label
                      htmlFor="Campaña"
                      className="block mb-2 font-bold text-gray-700"
                    >
                      Campaña
                    </label>
                    <Field
                      as="select"
                      name="Campaña"
                      className="px-4 py-2 input"
                      readOnly={readOnly}
                    >
                      <option value="" disabled selected={true}>
                        Selecciona
                      </option>
                      <option value="Hola">Selecciona</option>
                    </Field>
                    <span className="mb-4 text-red-500">
                      <ErrorMessage name="Campaña" className="text-7xl" />
                    </span>
                  </div>
                  {/* !SECTION Campaña */}

                  {/* SECTION TipoDeServicio */}
                  <div className="flex flex-col items-stretch">
                    <label
                      htmlFor="TipoDeServicio"
                      className="block mb-2 font-bold text-gray-700"
                    >
                      Tipo de servicio
                    </label>
                    <Field
                      as="select"
                      name="TipoDeServicio"
                      className="px-4 py-2 input"
                      readOnly={readOnly}
                    >
                      <option value="" disabled selected={true}>
                        Selecciona
                      </option>
                    </Field>
                    <span className="mb-4 text-red-500">
                      <ErrorMessage
                        name="TipoDeServicio"
                        className="text-7xl"
                      />
                    </span>
                  </div>
                  {/* !SECTION TipoDeServicio */}

                  {/* SECTION Subservicio */}
                  <div className="flex flex-col items-stretch">
                    <label
                      htmlFor="Subservicio"
                      className="block mb-2 font-bold text-gray-700"
                    >
                      Subservicio
                    </label>
                    <Field
                      as="select"
                      name="Subservicio"
                      className="px-4 py-2 input"
                      readOnly={readOnly}
                    >
                      <option value="" disabled selected={true}>
                        Selecciona
                      </option>
                    </Field>
                    <span className="mb-4 text-red-500">
                      <ErrorMessage name="Subservicio" className="text-7xl" />
                    </span>
                  </div>
                  {/* !SECTION Subservicio */}

                  {/* SECTION TipoDeIncidencia */}
                  <div className="flex flex-col items-stretch">
                    <label
                      htmlFor="TipoDeIncidencia"
                      className="block mb-2 font-bold text-gray-700"
                    >
                      Tipo de incidencia
                    </label>
                    <Field
                      as="select"
                      name="TipoDeIncidencia"
                      className="px-4 py-2 input"
                      readOnly={readOnly}
                    >
                      <option value="" disabled selected={true}>
                        Selecciona
                      </option>
                    </Field>
                    <span className="mb-4 text-red-500">
                      <ErrorMessage
                        name="TipoDeIncidencia"
                        className="text-7xl"
                      />
                    </span>
                  </div>
                  {/* !SECTION TipoDeIncidencia */}

                  {/* SECTION TipoDeIncidenciaSAP */}
                  <div className="flex flex-col items-stretch">
                    <label
                      htmlFor="TipoDeIncidenciaSAP"
                      className="block mb-2 font-bold text-gray-700"
                    >
                      Tipo de incidencia (SAP)
                    </label>
                    <Field
                      as="select"
                      name="TipoDeIncidenciaSAP"
                      className="px-4 py-2 input"
                      readOnly={readOnly}
                    >
                      <option value="" disabled selected={true}>
                        Selecciona
                      </option>
                    </Field>
                    <span className="mb-4 text-red-500">
                      <ErrorMessage
                        name="TipoDeIncidenciaSAP"
                        className="text-7xl"
                      />
                    </span>
                  </div>
                  {/* !SECTION TipoDeIncidenciaSAP */}

                  {/* SECTION SS */}
                  <div className="flex flex-col items-stretch">
                    <label
                      htmlFor="SS"
                      className="block mb-2 font-bold text-gray-700"
                    >
                      SS
                    </label>
                    <Field
                      as="select"
                      name="SS"
                      className="px-4 py-2 input"
                      readOnly={readOnly}
                    >
                      <option value="" disabled selected={true}>
                        Selecciona
                      </option>
                    </Field>
                    <span className="mb-4 text-red-500">
                      <ErrorMessage name="SS" className="text-7xl" />
                    </span>
                  </div>
                  {/* !SECTION SS */}

                  {/* SECTION Servicio */}
                  <div className="flex flex-col items-stretch">
                    <label
                      htmlFor="Servicio"
                      className="block mb-2 font-bold text-gray-700"
                    >
                      Servicio
                    </label>
                    <Field
                      as="select"
                      name="Servicio"
                      className="px-4 py-2 input"
                      readOnly={readOnly}
                    >
                      <option value="" disabled selected={true}>
                        Selecciona
                      </option>
                    </Field>
                    <span className="mb-4 text-red-500">
                      <ErrorMessage name="Servicio" className="text-7xl" />
                    </span>
                  </div>
                  {/* !SECTION Servicio */}

                  {/* SECTION ACE */}
                  <div className="flex flex-col items-stretch">
                    <label
                      htmlFor="ACE"
                      className="block mb-2 font-bold text-gray-700"
                    >
                      ACE
                    </label>
                    <Field
                      as="select"
                      name="ACE"
                      className="px-4 py-2 input"
                      readOnly={readOnly}
                    >
                      <option value="" disabled selected={true}>
                        Selecciona
                      </option>
                    </Field>
                    <span className="mb-4 text-red-500">
                      <ErrorMessage name="ACE" className="text-7xl" />
                    </span>
                  </div>
                  {/* !SECTION ACE */}

                  {/* SECTION TipoDeVozDelCliente */}
                  <div className="flex flex-col items-stretch">
                    <label
                      htmlFor="TipoDeVozDelCliente"
                      className="block mb-2 font-bold text-gray-700"
                    >
                      Tipo de voz del cliente
                    </label>
                    <Field
                      as="select"
                      name="TipoDeVozDelCliente"
                      className="px-4 py-2 input"
                      readOnly={readOnly}
                    >
                      <option value="" disabled selected={true}>
                        Selecciona
                      </option>
                    </Field>
                    <span className="mb-4 text-red-500">
                      <ErrorMessage
                        name="TipoDeVozDelCliente"
                        className="text-7xl"
                      />
                    </span>
                  </div>
                  {/* !SECTION TipoDeVozDelCliente */}

                  {/* SECTION CausaDeVozDelCliente */}
                  <div className="flex flex-col items-stretch">
                    <label
                      htmlFor="CausaDeVozDelCliente"
                      className="block mb-2 font-bold text-gray-700"
                    >
                      Causa de voz del cliente
                    </label>
                    <Field
                      as="select"
                      name="CausaDeVozDelCliente"
                      className="px-4 py-2 input"
                      readOnly={readOnly}
                    >
                      <option value="" disabled selected={true}>
                        Selecciona
                      </option>
                    </Field>
                    <span className="mb-4 text-red-500">
                      <ErrorMessage
                        name="CausaDeVozDelCliente"
                        className="text-7xl"
                      />
                    </span>
                  </div>
                  {/* !SECTION CausaDeVozDelCliente */}

                  {/* SECTION CanalDeEntrada */}
                  <div className="flex flex-col items-stretch">
                    <label
                      htmlFor="CanalDeEntrada"
                      className="block mb-2 font-bold text-gray-700"
                    >
                      Canal de entrada
                    </label>
                    <Field
                      as="select"
                      name="CanalDeEntrada"
                      className="px-4 py-2 input"
                      readOnly={readOnly}
                    >
                      <option value="" disabled selected={true}>
                        Selecciona
                      </option>
                    </Field>
                    <span className="mb-4 text-red-500">
                      <ErrorMessage
                        name="CanalDeEntrada"
                        className="text-7xl"
                      />
                    </span>
                  </div>
                  {/* !SECTION CanalDeEntrada */}

                  {/* SECTION SituacionPresentada */}
                  <div className="flex flex-col items-stretch">
                    <label
                      htmlFor="SituacionPresentada"
                      className="block mb-2 font-bold text-gray-700"
                    >
                      Situación Presentada
                    </label>
                    <Field
                      as="select"
                      name="SituacionPresentada"
                      className="px-4 py-2 input"
                      readOnly={readOnly}
                    >
                      <option value="" disabled selected={true}>
                        Selecciona
                      </option>
                    </Field>
                    <span className="mb-4 text-red-500">
                      <ErrorMessage
                        name="SituacionPresentada"
                        className="text-7xl"
                      />
                    </span>
                  </div>
                  {/* !SECTION SituacionPresentada */}

                  {/* SECTION NombreDeTienda */}
                  <div className="flex flex-col items-stretch">
                    <label
                      htmlFor="NombreDeTienda"
                      className="block mb-2 font-bold text-gray-700"
                    >
                      Nombre de la tienda
                    </label>
                    <Field
                      type="text"
                      name="NombreDeTienda"
                      className="px-4 py-2 input"
                      placeholder="Llenar"
                      readOnly={readOnly}
                    />
                    <span className="mb-4 text-red-500">
                      <ErrorMessage
                        name="NombreDeTienda"
                        className="text-7xl"
                      />
                    </span>
                  </div>
                  {/* !SECTION NombreDeTienda */}

                  {/* SECTION NumeroDeTienda */}
                  <div className="flex flex-col items-stretch">
                    <label
                      htmlFor="NumeroDeTienda"
                      className="block mb-2 font-bold text-gray-700"
                    >
                      Número de la tienda
                    </label>
                    <Field
                      type="text"
                      name="NumeroDeTienda"
                      className="px-4 py-2 input"
                      placeholder="Llenar"
                      readOnly={readOnly}
                    />
                    <span className="mb-4 text-red-500">
                      <ErrorMessage
                        name="NumeroDeTienda"
                        className="text-7xl"
                      />
                    </span>
                  </div>
                  {/* !SECTION NumeroDeTienda */}

                  {/* SECTION EjecutivoDeCalidad */}
                  <div className="flex flex-col items-stretch">
                    <label
                      htmlFor="EjecutivoDeCalidad"
                      className="block mb-2 font-bold text-gray-700"
                    >
                      Ejecutivo de calidad
                    </label>
                    <Field
                      name="EjecutivoDeCalidad"
                      type="text"
                      placeholder="Llenar"
                      className="px-4 py-2 input"
                      readOnly={readOnly}
                    />
                    <span className="mb-4 text-red-500">
                      <ErrorMessage
                        name="EjecutivoDeCalidad"
                        className="text-7xl"
                      />
                    </span>
                  </div>
                  {/* !SECTION EjecutivoDeCalidad */}
                </div>
                <div
                  role="group"
                  aria-labelledby="checkbox-group"
                  className="grid items-stretch grid-cols-1 my-4 gap-x-8 gap-y-4 md:grid-cols-2"
                >
                  {/* Procede queja */}
                  <label
                    htmlFor="ProcedeQueja"
                    className="flex flex-row items-center gap-4 input-label"
                  >
                    <Field
                      type="checkbox"
                      name="ProcedeQueja"
                      className="input"
                      readOnly={readOnly}
                    />
                    Procede queja
                  </label>

                  {/* Cliente problemático */}
                  <label
                    htmlFor="ClienteProblematico"
                    className="flex flex-row items-center gap-4 input-label"
                  >
                    <Field
                      type="checkbox"
                      name="ClienteProblematico"
                      className="input"
                      readOnly={readOnly}
                    />
                    Cliente Problemático
                  </label>

                  {/* Incidencia mayor */}
                  <label
                    htmlFor="IncidenciaMayor"
                    className="flex flex-row items-center gap-4 input-label"
                  >
                    <Field
                      type="checkbox"
                      name="IncidenciaMayor"
                      className="input"
                      readOnly={readOnly}
                    />
                    Incidencia Mayor
                  </label>

                  {/* Cliente VIP  */}
                  <label
                    htmlFor="ClienteVIP"
                    className="flex flex-row items-center gap-4 input-label"
                  >
                    <Field
                      type="checkbox"
                      name="ClienteVIP"
                      className="input"
                      readOnly={readOnly}
                    />
                    Cliente VIP
                  </label>
                </div>
              </>
            )}
            {/* !SECTION Información de la queja  */}

            {!readOnly && (
              <React.Fragment>
                {/* SECTION Files */}
                <div className="grid items-stretch grid-cols-4 gap-4">
                  {/* File 1 */}
                  <div className="mx-auto my-8 overflow-hidden rounded-lg cursor-pointer">
                    <div className="cursor-pointer md:flex">
                      <div className="w-full p-3">
                        <div className="relative flex items-center justify-center h-48 bg-gray-100 border-2 border-blue-700 border-dashed rounded-lg hover:bg-indigo-300">
                          <div className="absolute">
                            <div className="flex flex-col items-center">
                              <i className="text-3xl bi bi-image"></i>
                              <span className="block font-normal">
                                Añadir imágenes.
                              </span>
                            </div>
                          </div>
                          <input
                            type="file"
                            className="w-full h-full opacity-0 cursor-pointer hover:bg-indigo-800"
                            name="Archivos"
                            multiple
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* File 2 */}
                  <div className="max-w-md mx-auto my-8 overflow-hidden rounded-lg cursor-pointer md:max-w-xl">
                    <div className="cursor-pointer md:flex">
                      <div className="w-full p-3">
                        <div className="relative flex items-center justify-center h-48 bg-gray-100 border-2 border-blue-700 border-dashed rounded-lg hover:bg-indigo-300">
                          <div className="absolute">
                            <div className="flex flex-col items-center">
                              <i className="text-3xl bi bi-play-btn"></i>
                              <span className="block font-normal">
                                Añadir videos.
                              </span>
                            </div>
                          </div>
                          <input
                            type="file"
                            className="w-full h-full opacity-0 cursor-pointer hover:bg-indigo-800"
                            name="Archivos"
                            multiple
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* File 3 */}
                  <div className="max-w-md mx-auto my-8 overflow-hidden rounded-lg cursor-pointer md:max-w-xl">
                    <div className="cursor-pointer md:flex">
                      <div className="w-full p-3">
                        <div className="relative flex items-center justify-center h-48 bg-gray-100 border-2 border-blue-700 border-dashed rounded-lg hover:bg-indigo-300">
                          <div className="absolute">
                            <div className="flex flex-col items-center">
                              <i className="text-3xl bi bi-mic"></i>
                              <span className="block font-normal">
                                Añade archivos de voz.
                              </span>
                            </div>
                          </div>
                          <input
                            type="file"
                            className="w-full h-full opacity-0 cursor-pointer hover:bg-indigo-800"
                            name="Archivos"
                            multiple
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* File 4 */}
                  <div className="max-w-md mx-auto my-8 overflow-hidden rounded-lg cursor-pointer md:max-w-xl">
                    <div className="cursor-pointer md:flex">
                      <div className="w-full p-3">
                        <div className="relative flex items-center justify-center h-48 bg-gray-100 border-2 border-blue-700 border-dashed rounded-lg hover:bg-indigo-300">
                          <div className="absolute">
                            <div className="flex flex-col items-center">
                              <i className="text-3xl bi bi-files"></i>
                              <span className="block font-normal">
                                Añade PDF, Excel, etc.
                              </span>
                            </div>
                          </div>
                          <input
                            type="file"
                            className="w-full h-full opacity-0 cursor-pointer hover:bg-indigo-800"
                            name="Archivos"
                            multiple
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* !SECTION Files */}
                <div className="text-center">
                  <button className={"button-primary px-4 mt-4"} type="submit">
                    Guardar información
                  </button>
                </div>
              </React.Fragment>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default InformacionDeLaQueja;
