import { useState } from "react";
import Message, { MessageProps } from "../atoms/Message";
import { DateTime } from "luxon";

export interface ChatboxProps {
  Messages: MessageProps[];
  Chatbar?: boolean;
}

const Chatbox = ({ Messages, Chatbar = true }: ChatboxProps): JSX.Element => {
  const [message, setMessage] = useState("");

  const [mensajes, setMensajes] = useState<ChatboxProps["Messages"]>(Messages);

  const handleMessage = () => {
    var now = DateTime.now();
    const fecha = `${now.toLocaleString(DateTime.DATETIME_SHORT)}`;
    message !== "" &&
      setMensajes([
        ...mensajes,
        {
          Recibido: false,
          Nombre: "Tú",
          Mensaje: message,
          Fecha: fecha,
        },
      ]);
    setMessage("");
  };

  return (
    <div>
      <div
        className="overflow-y-auto bg-gray-100 border-2 border-b-0 rounded shadow"
        style={{ maxHeight: "70vh" }}
      >
        <div className="grid gap-4 p-2 cols-12">
          {mensajes.map((mensaje, i) => (
            <Message {...mensaje} key={i} />
          ))}
        </div>

        {mensajes.length < 1 && (
          <div className="w-full text center">
            <p className="pb-4 mx-auto text-center text-gray-400">
              Envía el primer mensaje para comenzar la solicitud.
            </p>
          </div>
        )}
      </div>
      {Chatbar && (
        <div className="flex flex-row gap-4 px-2 py-4 bg-white border-2 border-t-0 shadow">
          <input
            type="text"
            name="Mensaje"
            id="Mensaje"
            className="w-11/12 input"
            placeholder="Envía un mensaje"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <button
            className="w-1/12 px-4 text-center rounded-full button-primary"
            onClick={() => handleMessage()}
          >
            <i className="bi bi-arrow-up text white"></i>
          </button>
        </div>
      )}
    </div>
  );
};

export default Chatbox;
