import React from "react";
import Lottie from "react-lottie";
import animationData from "src/assets/lotties/loader.json";

const defaultLoaderOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const Loader = (props: any) => {
  if (props.loader === false) return null;
  return (
    <div
      className={`fixed z-10 justify-center w-screen h-screen bg-gray-100 ${
        props.loader ? `opacity-50` : `opacity-0`
      } `}
    >
      <div className="opacity-100">
        <Lottie
          options={defaultLoaderOptions}
          height={600}
          width={600}
          isStopped={false}
          isPaused={false}
        />
      </div>
    </div>
  );
};

export default Loader;
