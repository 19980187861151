const Searchbar = (): JSX.Element => {
  return (
    <div className="grid items-stretch w-full grid-cols-12 space-x-2">
      {/* Search bar  */}
      <div className="relative col-span-10">
        <input
          type="text"
          name="Search"
          id="search"
          className="w-full h-full px-4 input md:px-12"
          placeholder="Busca una queja"
        />
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <i className="hidden text-gray-300 fill-current md:block bi bi-search md:text-2xl"></i>
        </div>
      </div>
      {/* END OF: Search bar  */}

      {/* Filter button  */}
      <button className="hidden md:block md:col-span-1 button-primary">
        <i className="text-xs bi bi-filter md:text-2xl"></i>
      </button>
      {/* END OF: Filter button  */}

      {/* Assigned button  */}
      <button className="col-span-2 button-primary md:col-span-1">
        <i className="bi bi-people md:text-2xl"></i>
      </button>
      {/* END OF: Assigned button  */}
    </div>
  );
};

export default Searchbar;
