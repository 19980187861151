export function formatCreditCard(value: string): string {
    var v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
    var matches = v.match(/\d{4,16}/g);
    // eslint-disable-next-line no-mixed-operators
    var match = matches && matches[0] || ''
    var parts = []

    for (let i=0, len=match.length; i<len; i +=4) {
        parts.push(match.substring(i, i+4))
    }

    if (parts.length) {
        return parts.join(' ')
    } else {
        return value
    }
}

export const formatMoney = (number: number) => {
    const format = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        currencyDisplay: "symbol",
    })
    return format.format(number);
}