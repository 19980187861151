import { Link } from "react-router-dom";

export interface CardProps {
  id: number;
  title: string;
  titles: string[];
  content: CardItemProps[];
}

export interface CardItemProps {
  CASC: string;
  Urgencia: 1 | 2 | 3;
  TipoDeCanal: string;
  TipoDeServicio: string;
  TipoDeIncidencia: string;
  FechaDeSolicitud: string;
  FechaDeReporte: string;
  TiempoRestante: number;
  Severidad: string;
  Etiquetas: EtiquetasProps[];
  Asignado: string;
}

export interface EtiquetasProps {
  Nombre: string;
  Color: string;
}

const Card = ({ id, title, titles, content }: CardProps): JSX.Element => {
  return (
    <div className="flex flex-col items-stretch overflow-x-auto rounded shadow">
      <div className="table-row-group px-4 py-2 text-lg text-white bg-indigo-900 md:py-4 rounded-t-2xl">
        {id}. {title}
      </div>
      <div className="table w-full rounded-full shadow">
        <div className="table-header-group bg-indigo-200">
          {titles.map((title) => (
            <div className="flex-row table-cell py-2 text-xs text-center text-indigo-900 transition-all cursor-pointer hover:text-indigo-500 md:text-base">
              <i className="bi bi-chevron-down"></i>
              {title}
            </div>
          ))}
        </div>
        {content.map((CASC, CASCindex) => (
          <Link
            className={`table-row text-center text-xs md:text-base md:px-4 py-8 hover:bg-gray-200 cursor-pointer ${
              CASCindex % 2 === 0 ? `bg-white` : `bg-gray-100`
            }`}
            to={`${process.env.PUBLIC_URL}/casc`}
            key={CASCindex}
          >
            <div className="table-cell py-2 text-center md:px-3">
              <i
                className={`bi bi-exclamation-circle-fill mr-1 ${
                  CASC.Urgencia > 1 ? `visible` : `invisible`
                } ${CASC.Urgencia > 2 ? `text-red-500` : `text-yellow-300`}`}
              ></i>
              {CASC.CASC}
            </div>
            <div className="table-cell py-2">{CASC.TipoDeCanal}</div>
            <div className="table-cell py-2">{CASC.TipoDeServicio}</div>
            <div className="table-cell py-2">{CASC.TipoDeIncidencia}</div>
            <div className="table-cell py-2">{CASC.FechaDeSolicitud}</div>
            <div className="relative table-cell py-2">
              <div className="absolute inset-x-0 z-0 align-middle bg-gray-700 rounded-full h-min">
                <div className="absolute z-10 w-1/2 h-full bg-red-500 rounded-full">
                  {" "}
                </div>
                <p className="relative z-20 text-gray-100">
                  {CASC.FechaDeReporte}
                </p>
              </div>
            </div>
            <div
              className={`table-cell py-2 ${
                CASC.Severidad === "Alta"
                  ? `text-red-500`
                  : CASC.Severidad === "Media"
                  ? `text-yellow-300`
                  : `text-green-500`
              } `}
            >
              {CASC.Severidad === "Alta" && (
                <i className="text-red-500 bi bi-exclamation-triangle"></i>
              )}
              {CASC.Severidad === "Media" && (
                <i className="text-yellow-300 bi bi-exclamation-circle"></i>
              )}
              <span className="pl-1">{CASC.Severidad}</span>
            </div>
            <div className="table-cell py-2">
              <div className="flex flex-wrap justify-center gap-1 text-xs">
                {CASC.Etiquetas.map((etiqueta) => (
                  <p
                    className={`rounded-full border-2 text-${etiqueta.Color} border-${etiqueta.Color} px-2`}
                  >
                    {etiqueta.Nombre}
                  </p>
                ))}
              </div>
            </div>
            <div className="table-cell py-2 md:px-4">{CASC.Asignado}</div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Card;
