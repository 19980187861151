import { ErrorMessage, Field, Form, Formik } from "formik";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { toast } from "react-toastify";
import ImgBanner from "../assets/banner.svg";
import LogoClub from "../assets/logos/club_de_asistencias.svg";
import Api from "src/helpers/instances";

const Login = (props: any): JSX.Element => {
  // Hook para obtener el historial de la aplicación
  const history = useHistory();

  const setLogin = (token: string, setSubmitting: any) => {
    setSubmitting(false);
    props.setToken(token);
    localStorage.setItem("token", token);
    history.push(`${process.env.PUBLIC_URL}/home`);
  };

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      validationSchema={Yup.object({
        email: Yup.string()
          .email("Dirección de correo inválida.")
          .required("Campo obligatorio."),
        password: Yup.string().required("Campo obligatorio."),
      })}
      onSubmit={(values, { setSubmitting }) => {
        const { email, password } = values;
        Api.post(`login`, {
          email,
          password,
        }).then((res: any) =>
          res.success === 1
            ? setLogin(res.token, setSubmitting)
            : toast.error(res.message)
        );
      }}
    >
      <div className="flex items-center content-center h-full p-4 align-middle">
        <div className="container flex flex-row justify-between p-6 mx-auto my-auto align-middle bg-white rounded shadow-lg">
          <div className="relative hidden xl:block">
            <img src={ImgBanner} alt="Banner" className="xl:w-full" />
            <img
              src={LogoClub}
              alt="Logo club de asistencias"
              className="absolute top-1/3 left-1/3"
            />
          </div>
          <div className="w-1/2 mx-auto md:w-1/3">
            <h1 className="mb-8 text-4xl text-gray-700 md:text-6xl">
              Bienvenido
            </h1>
            <Form className="my-auto align-middle flex-columns">
              <div className="flex flex-col items-stretch">
                <label
                  htmlFor="email"
                  className="block mb-2 font-bold text-gray-700"
                >
                  Usuario
                </label>
                <Field
                  name="email"
                  type="email"
                  placeholder="Usuario"
                  className="p-4 input"
                />
                <span className="mb-4 text-red-500">
                  <ErrorMessage name="email" className="text-7xl" />
                </span>
              </div>
              <div className="flex flex-col items-stretch">
                <label
                  htmlFor="password"
                  className="block mb-2 font-bold text-gray-700"
                >
                  Contraseña
                </label>
                <Field
                  name="password"
                  type="password"
                  placeholder="Usuario"
                  className="p-4 input"
                />
                <span className="mb-4 text-red-500">
                  <ErrorMessage name="password" className="text-7xl" />
                </span>
              </div>
              <div className="flex flex-col items-stretch mt-4 text-left">
                <p className="text-indigo-900 cursor-pointer hover:underline">
                  ¿Olvidaste tu contraseña?
                </p>
                <button className="items-stretch p-4 text-white transition-all bg-indigo-900 rounded shadow hover:bg-indigo-700">
                  Inicia sesión
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Formik>
  );
};

export default Login;
