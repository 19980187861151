import useSWR from 'swr';
import Api from 'src/helpers/instances'

export const fetcher = (url: string, data: object | null) => {
    if (!data) {
        return Api.get(url).then((res: any) => res.event)
    }

    return Api.post(url, data).then((res: any) => res.event)
}

export default fetcher;