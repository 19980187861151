export interface TabProps {
  selected?: boolean;
  nombre: string;
  ID_Modulos: number;
  descripcion: "";
  estatus: "Activo";
  ID_MenuPrincipal: number;
  permiso: boolean;
  icon: string;
  setView: (view: number) => void;
}

const Tab = ({
  selected,
  nombre,
  icon,
  setView,
  ID_Modulos,
}: TabProps): JSX.Element => {
  return (
    <div
      className={`flex flex-row py-2 w-full justify-center px-2 border-b-4 transition-all cursor-pointer space-x-1 ${
        selected
          ? `bg-indigo-100 text-indigo-700 font-bold border-indigo-700`
          : `bg-gray-100 text-gray-800 border-gray-300 hover:border-gray-500 hover:text-gray-500 hover:bg-gray-200`
      }`}
      onClick={() => setView(ID_Modulos)}
    >
      <i className="bi bi-kanban"></i>
      <p className="hidden md:block">{nombre}</p>
    </div>
  );
};

export default Tab;
