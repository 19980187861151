import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import Layout from "./components/layout/Layout";
import CASC from "./views/CASC";
import Login from "./views/Login";
import Tablero from "./views/Tablero";
import NuevoCASC from "./views/NuevoCASC";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdministracionDeUsuarios from "./views/AdministracionDeUsuarios";
import { useEffect, useState } from "react";
import { SWRConfig } from "swr";
import { toast } from "react-toastify";
import api from "./helpers/instances";

function App(): JSX.Element {
  // Token que se obtiene al inicio de la aplicación
  const [token, setToken] = useState(localStorage.getItem("token"));

  // Si la sesión expiró (y el usuariio fue redirigido) le damos una notificación
  useEffect(() => {
    if (localStorage.getItem("expired") === "1") {
      toast.error("Tu sesión ha expirado. Inicia sesión nuevamente");
      localStorage.clear();
    }
  }, []);

  // Se guardan los permisos cada vez que se obtiene el token
  useEffect(() => {
    api
      .get(`obetner_perfil_permisos`)
      .then((res: any) =>
        sessionStorage.setItem("permisos", JSON.stringify(res.event))
      );
  }, [token]);

  return (
    <Router>
      <SWRConfig
        value={{
          onError: (error, key) => {
            // Token inválido
            if (error.response.status === 401) {
              // Borramos el toda la info de la sesión pues ya es inválido.
              localStorage.clear();
              sessionStorage.clear();
              // Guardamos una bandera de expirado (para cuando llegue al login)
              localStorage.setItem("expired", "1");
              window.location.href = `${process.env.PUBLIC_URL}/home`;
            }
          },
        }}
      >
        <Switch>
          <Route exact path={`${process.env.PUBLIC_URL}/`}>
            {token !== null ? (
              <Redirect to={`${process.env.PUBLIC_URL}/home`} />
            ) : (
              <Redirect to={`${process.env.PUBLIC_URL}/login`} />
            )}
          </Route>
          <Route path={`${process.env.PUBLIC_URL}/login`}>
            <Login token={token} setToken={setToken} />
          </Route>
          {token !== null ? (
            <Layout>
              <Route path={`${process.env.PUBLIC_URL}/home`}>
                <Tablero />
              </Route>
              <Route path={`${process.env.PUBLIC_URL}/casc`}>
                <CASC />
              </Route>
              <Route path={`${process.env.PUBLIC_URL}/nuevo-casc`}>
                <NuevoCASC />
              </Route>
              <Route
                path={`${process.env.PUBLIC_URL}/administracion-de-usuarios`}
              >
                <AdministracionDeUsuarios />
              </Route>
            </Layout>
          ) : (
            <Redirect to={`${process.env.PUBLIC_URL}/login`} />
          )}

          <Route path="*">
            <Redirect to={`${process.env.PUBLIC_URL}/login`} />
          </Route>
        </Switch>

        {/* Notification */}
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </SWRConfig>
    </Router>
  );
}

export default App;
