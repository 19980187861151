export interface MessageProps {
  Recibido: boolean;
  Nombre: string;
  Mensaje: string;
  Fecha?: string | Date;
}

const Message = ({
  Recibido,
  Mensaje,
  Nombre,
  Fecha,
}: MessageProps): JSX.Element => {
  return (
    <div
      className={`col-span-12 px-4 py-2 shadow rounded-xl ${
        Recibido ? `col-end-10 bg-indigo-200` : `col-start-2 bg-white`
      }`}
    >
      <div className="flex flex-row justify-between">
        <p className="font-bold">{Nombre}</p>
        <p className="text-sm">{Fecha}</p>
      </div>
      {Mensaje}
    </div>
  );
};

export default Message;
