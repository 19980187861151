import NavBar from "./NavBar";

interface LayoutProps {
  children: React.ReactNode;
}

const Layout = ({ children }: LayoutProps): JSX.Element => {
  return (
    <div
      className="flex flex-col flex-grow h-full lg:flex-row"
      style={{ minHeight: "100vh" }}
    >
      <NavBar />
      <div className="flex flex-col w-full p-2 overflow-hidden md:p-4">
        {children}
      </div>
    </div>
  );
};

export default Layout;
