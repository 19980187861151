import { Link, useLocation, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useLoader } from "src/index";
import fetcher from "src/helpers/fetcher";
import useSWR from "swr";
import { useState } from "react";

interface menuButtonType {
  ID_MenuPrincipal: number;
  nombre: string;
  descripcion: string;
  estatus: string;
  icon: string;
  url: string;
  iconAlt: string;
  permiso: boolean;
}

const NavBar = (): JSX.Element => {
  const location = useLocation();
  const history = useHistory();
  const [loader, setLoader] = useLoader();
  const { data, error } = useSWR(`obtener_menu_principal`, fetcher);
  const [hoverExit, setHoverExit] = useState(false);

  const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    history.push(`${process.env.PUBLIC_URL}/login`);
  };

  setLoader(data ? false : true);

  if (error) toast.error(error.response.message);

  return (
    <div
      className={`bg-white shadow-sm flex flex-row lg:flex-col justify-evenly lg:justify-start py-2 lg:px-4 lg:h-max lg:py-4 lg:space-y-4`}
    >
      {data?.map((button: menuButtonType) => {
        if (button.permiso)
          return (
            <Link
              to={`${process.env.PUBLIC_URL}${button.url}`}
              key={button.ID_MenuPrincipal}
            >
              <div
                className={`flex flex-row px-4 py-0 lg:py-2 items-center justify-start w-full rounded-md space-x-2 hover:bg-gray-200 hover:text-gray-500 transition-all ${
                  location.pathname === `${process.env.PUBLIC_URL}${button.url}`
                    ? `bg-indigo-100  text-indigo-700`
                    : `text-gray-800`
                }`}
              >
                <i className={button.icon}></i>
                <h1
                  className={`hidden lg:block text-sm md:text-lg align-middle`}
                >
                  {button.nombre}
                </h1>
              </div>
            </Link>
          );
      })}
      <div
        className="flex flex-row items-center justify-start px-4 py-0 space-x-2 transition-all rounded-md cursor-pointer lg:w-full w-min lg:py-2 hover:bg-gray-200 hover:text-gray-500"
        onMouseEnter={() => setHoverExit(true)}
        onMouseLeave={() => setHoverExit(false)}
        onClick={() => logout()}
      >
        <i
          className={`bi bi-${hoverExit ? `door-open-fill` : `door-closed`}`}
        ></i>
        <h1 className={`hidden lg:block text-sm md:text-lg align-middle`}>
          Salir
        </h1>
      </div>
    </div>
  );
};

export default NavBar;
