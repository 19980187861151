import { useState } from "react";
import { TiempoDelProveedor } from "src/helpers/colorizers";

export interface HeaderProps {
  Nombre?: string;
  Urgencia: 1 | 2 | 3;
  Folio?: number;
  FolioSis?: string;
  CAS?: string;
  FechaReporte?: string;
  Ejecutivo?: string;
  Titular?: string;
  Cuenta?: number;
  Celular?: number | string;
}

const Header = ({
  Nombre,
  Urgencia,
  Folio,
  FolioSis,
  CAS,
  FechaReporte,
  Ejecutivo,
  Titular,
  Cuenta,
  Celular,
}: HeaderProps): JSX.Element => {
  const [showInfo, setShowInfo] = useState(true);
  return (
    <div>
      <div
        className={`w-full flex flex-row justify-between ${
          !showInfo && `pb-4`
        }`}
      >
        <div>
          {/* SECTION Title CASC */}
          <p className="text-xl font-extrabold text-indigo-800">
            {Nombre}{" "}
            {Urgencia > 1 && (
              <i
                className={`bi bi-exclamation-circle-fill ${
                  Urgencia > 2 ? `text-red-500` : `text-yellow-300`
                }`}
              ></i>
            )}
          </p>
          {/* !SECTION Title CASC */}

          {/* SECTION Progressbar */}
          <div className="relative">
            <div className="flex h-2 my-2 overflow-hidden text-xs bg-gray-200 rounded">
              <div
                style={{
                  width: `${70}%`,
                }}
                className={`flex flex-col justify-center text-center text-white rounded-full shadow-none whitespace-nowrap ${TiempoDelProveedor(
                  70
                )}`}
              ></div>
            </div>
          </div>
          {/* !SECTION Progressbar */}
        </div>
        <div className="text-xl rounded-full cursor-pointer hover:bg-indigo-100">
          <i
            className={`bi bi-chevron-${showInfo ? `contract` : `expand`}`}
            onClick={() => setShowInfo(!showInfo)}
          ></i>
        </div>
      </div>
      {showInfo && (
        <div className="grid justify-between grid-cols-2 py-4 lg:grid-cols-3 xl:grid-cols-4 md:text-md lg:text-lg">
          <p>
            <span className="font-bold">Folio: </span>
            {Folio}
          </p>
          <p>
            <span className="font-bold">Folio SIS: </span>
            {FolioSis}
          </p>
          <p>
            <span className="font-bold">CAS: </span>
            {CAS}
          </p>
          <p>
            <span className="font-bold">Fecha de reporte: </span>
            {FechaReporte}
          </p>
          <p>
            <span className="font-bold">Ejecutivo: </span>
            {Ejecutivo}
          </p>
          <p>
            <span className="font-bold">Titular: </span>
            {Titular}
          </p>
          <p>
            <span className="font-bold">Cuenta: </span>
            {Cuenta}
          </p>
          <p>
            <span className="font-bold">Celular: </span>
            {Celular}
          </p>
        </div>
      )}
      <hr className="mb-4 border-2" />
    </div>
  );
};

export default Header;
