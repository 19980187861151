import { useState } from "react";
import ResumenDeLaQueja from "src/components/casc/bodies/6";
import RetroalimentacionDelProveedor from "src/components/casc/bodies/2";
import SeguimientoConElCliente from "src/components/casc/bodies/3";
import AplicacionDelPago from "src/components/casc/bodies/4";
import InformacionDeLaQueja, {
  InformacionDelFormProps,
} from "../components/casc/bodies/1";
import Header, { HeaderProps } from "../components/casc/Header";
import Tab from "../components/casc/Tab";
import useSWR from "swr";
import fetcher from "src/helpers/fetcher";
import { useLoader } from "src";
import { TabProps } from "src/components/casc/Tab";

// Sólo en lo que se corrige este relajo
const salvaguardame = [
  "CASC",
  "Administración de Usuarios",
  "Administración de Perfiles",
  "Solicitud de Retroalimentación al Proveedor",
];

const Casc = (): JSX.Element => {
  const { data: tabs, error } = useSWR(`obtener_modulos`, fetcher);
  const [view, setView] = useState(tabs && tabs[0].ID_Modulos);
  const [loader, setLoader] = useLoader();

  tabs ? setLoader(false) : setLoader(true);

  // Header of the card
  const [header] = useState<HeaderProps>({
    Nombre: "CASC3490W",
    Urgencia: 3,
    Folio: 4850345,
    FolioSis: "1-4398-348",
    CAS: "CAS409F4",
    FechaReporte: "27/02/2021",
    Ejecutivo: "Michelle Rodriguez",
    Titular: "Juan Pérez",
    Cuenta: 482823,
    Celular: "55 2058 6853",
  });

  const FormDataProps: InformacionDelFormProps = {
    Nombres: "Pablo",
    ApellidoPaterno: "Vázquez",
    ApellidoMaterno: "García",
    Telefono: "5520586853",
    Celular: "5520586853",
    Correo: "algo@algo.com",
    Cuenta: "4548509",
    Severidad: 1,
    EjecutivoDeCalidad: "Michelle Rodriguez",
    ProcedeQueja: true,
  };

  const x = {
    ID_Modulos: 10,
    nombre: "Solicitud de Retroalimentación al Proveedor",
    descripcion: "",
    estatus: "Activo",
    ID_MenuPrincipal: 1,
    permiso: false,
  };

  // TODO: convertir las vistas en lazy loadings
  return (
    <div className="flex flex-col w-full bg-white rounded shadow">
      {/* SECTION Tabs  */}
      <div className="flex flex-row items-stretch">
        {tabs?.map((tab: TabProps) => {
          if (tab.permiso)
            return (
              <Tab
                {...tab}
                selected={view === tab.ID_Modulos ? true : false}
                setView={setView}
                key={tab.ID_Modulos}
              />
            );
        })}
      </div>
      {/* !SECTION Tabs */}

      {/* SECTION Body */}
      <div className="p-4">
        <Header {...header} />

        {view === 1 && (
          <InformacionDeLaQueja FormDataProps={FormDataProps} ReadOnly={true} />
        )}

        {view === 2 && <RetroalimentacionDelProveedor />}
        {view === 3 && <SeguimientoConElCliente />}
        {view === 4 && <AplicacionDelPago />}
        {view === 5 && <SeguimientoConElCliente />}
        {view === 6 && <ResumenDeLaQueja />}
      </div>
      {/* !SECTION Body */}
    </div>
  );
};

export default Casc;
