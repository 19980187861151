import React, { useState } from "react";
import { TiempoDelProveedor } from "src/helpers/colorizers";
import { formatCreditCard, formatMoney } from "src/helpers/formatters";

interface AplicacionDelPagoProps {
  Nuevo?: boolean;
}

interface ItemTablaDePago {
  TipoDePago: string;
  Programa: string;
  CostoDePrograma: number;
  NombreProveedor: string;
  CFDI: string;
  RFC: string;
  Subtotal: number;
  IVA: number;
  Retencion: number;
  MontoTotalFacturas: number;
  TotalNegociado: number;
  TotalAPagar: number;
  Comision: number;
  Titular: string;
  Tarjeta: string;
  Parentesco: string;
}

type TablaDePago = ItemTablaDePago[];

const AplicacionDelPago = ({ Nuevo = true }: AplicacionDelPagoProps) => {
  const [nuevoPago, setnuevoPago] = useState(Nuevo);
  const [showTablaDePago, setShowTablaDePago] = useState(true);
  const [showInfoDePago, setShowInfoDePago] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [pagoAutorizado, setPagoAutorizado] = useState(false);
  const [comprobanteDePago, setComprobanteDePago] = useState(false);
  const [editarTabla, setEditarTabla] = useState(false);
  const [campañaSeleccionada, setCampañaSeleccionada] = useState("");
  const [Tabla, setTabla] = useState<TablaDePago>([
    {
      TipoDePago: "PCAS004",
      Programa: "PIF Plus",
      CostoDePrograma: 1500.21,
      NombreProveedor: "Grúas Hernández",
      CFDI: "34574350",
      RFC: "VAGP990709",
      Subtotal: 1800.53,
      IVA: 74.5,
      Retencion: 50,
      MontoTotalFacturas: 1950.05,
      TotalNegociado: 450.69,
      TotalAPagar: 2150.6,
      Comision: 250,
      Titular: "Arturo Hernández",
      Tarjeta: "4258167315302596",
      Parentesco: "Cónyugue",
    },
    {
      TipoDePago: "PCAS004",
      Programa: "PIF Plus",
      CostoDePrograma: 1500.21,
      NombreProveedor: "Grúas Hernández",
      CFDI: "34574350",
      RFC: "VAGP990709",
      Subtotal: 1800.53,
      IVA: 74.5,
      Retencion: 50,
      MontoTotalFacturas: 1950.05,
      TotalNegociado: 450.69,
      TotalAPagar: 2150.6,
      Comision: 250,
      Titular: "Arturo Hernández",
      Tarjeta: "4258167315302596",
      Parentesco: "Cónyugue",
    },
    {
      TipoDePago: "PCAS004",
      Programa: "PIF Plus",
      CostoDePrograma: 1500.21,
      NombreProveedor: "Grúas Hernández",
      CFDI: "34574350",
      RFC: "VAGP990709",
      Subtotal: 1800.53,
      IVA: 74.5,
      Retencion: 50,
      MontoTotalFacturas: 1950.05,
      TotalNegociado: 450.69,
      TotalAPagar: 2150.6,
      Comision: 250,
      Titular: "Arturo Hernández",
      Tarjeta: "4258167315302596",
      Parentesco: "Cónyugue",
    },
    {
      TipoDePago: "PCAS004",
      Programa: "PIF Plus",
      CostoDePrograma: 1500.21,
      NombreProveedor: "Grúas Hernández",
      CFDI: "34574350",
      RFC: "VAGP990709",
      Subtotal: 1800.53,
      IVA: 74.5,
      Retencion: 50,
      MontoTotalFacturas: 1950.05,
      TotalNegociado: 450.69,
      Comision: 250,
      TotalAPagar: 2150.6,
      Titular: "Arturo Hernández",
      Tarjeta: "4258167315302596",
      Parentesco: "Cónyugue",
    },
  ]);

  const handleAutorizarPago = () => {
    setPagoAutorizado(true);
    setTimeout(() => {
      setComprobanteDePago(true);
    }, 4000);
  };

  if (nuevoPago)
    return (
      <div className="flex justify-center w-full p-4 mx-auto my-auto">
        <button
          className="px-4 button-primary"
          onClick={() => setnuevoPago(false)}
        >
          Generar nuevo ID de pago
        </button>
      </div>
    );

  return (
    <div className="flex flex-col">
      {/* SECTION Tabla de pago */}
      <div className="py-4">
        <div className="flex flex-row justify-between text-xl font-bold text-gray-800">
          <div>
            <i className="pb-1 pr-2 bi bi-cash"></i>
            Tabla de pago
          </div>
          <div className="text-xl rounded cursor-pointer hover:bg-indigo-100">
            <i
              className={`bi bi-chevron-${
                showTablaDePago ? `contract` : `expand`
              }`}
              onClick={() => setShowTablaDePago(!showTablaDePago)}
            ></i>
          </div>
        </div>
        <hr className="bg-gray-300" />
      </div>

      {showTablaDePago && (
        <div className="flex flex-col pb-8 align-baseline">
          {/* SECTION Table header */}
          <div className="flex flex-row justify-between gap-4 pb-2">
            <div className="flex flex-row gap-4">
              {/* SECTION Tipo de pago */}
              <div className="flex flex-col items-stretch">
                <label
                  htmlFor="TipoRetroalimentacion"
                  className="block mb-2 font-bold text-gray-700"
                >
                  Tipo de pago
                </label>
                <select
                  name="FechaSolicitud"
                  id="FechaSolicitud"
                  className="input"
                >
                  <option value="PCAS002">PCAS002</option>
                  <option value="PCAS004">PCAS004</option>
                  <option value="PCAS005">PCAS005</option>
                  <option value="PCAS006">PCAS006</option>
                </select>
              </div>
              {/* !SECTION Tipo de pago */}

              {/* SECTION Campaña */}
              <div className="flex flex-col items-stretch">
                <label
                  htmlFor="TipoRetroalimentacion"
                  className="block mb-2 font-bold text-gray-700"
                >
                  Campaña
                </label>
                <select
                  name="FechaSolicitud"
                  id="FechaSolicitud"
                  className="input"
                  defaultValue={campañaSeleccionada}
                  onChange={(e) => setCampañaSeleccionada(e.target.value)}
                >
                  <option value="HDI">HDI</option>
                  <option value="Liverpool">Liverpool</option>
                </select>
              </div>
              {/* !SECTION Campaña */}

              {/* SECTION Programa */}
              {campañaSeleccionada === "Liverpool" && (
                <div className="flex flex-col items-stretch">
                  <label
                    htmlFor="Pograma"
                    className="block mb-2 font-bold text-gray-700"
                  >
                    Programa
                  </label>
                  <select name="Programa" id="Programa" className="input">
                    <option value="PIF">PIF</option>
                    <option value="PIF Basico">PIF Basico</option>
                    <option value="PIF Extra">PIF Extra</option>
                  </select>
                </div>
              )}
              {/* !SECTION Programa */}

              {/* SECTION Num. de primas */}
              <div className="flex flex-col items-stretch">
                <label
                  htmlFor="TipoRetroalimentacion"
                  className="block mb-2 font-bold text-gray-700"
                >
                  Num. de primas
                </label>
                <input
                  type="text"
                  name="FechaSolicitud"
                  id="FechaSolicitud"
                  className="input"
                  disabled
                  value={Tabla.length}
                />
              </div>
              {/* !SECTION Num. de primas */}

              {/* SECTION Tipo de documento */}
              <div className="flex flex-col items-stretch">
                <label
                  htmlFor="TipoRetroalimentacion"
                  className="block mb-2 font-bold text-gray-700"
                >
                  Tipo de documento
                </label>
                <select
                  name="FechaSolicitud"
                  id="FechaSolicitud"
                  className="input"
                >
                  <option value="Factura">Factura</option>
                  <option value="Nota membretada">Nota membretada</option>
                  <option value="Bitácora">Bitácora</option>
                </select>
              </div>
              {/* !SECTION Tipo de documento */}
            </div>

            {/* SECTION Editar tabla  */}
            <div className="flex justify-end h-12 gap-4 align-baseline">
              {editarTabla && (
                <button
                  className="px-2 py-2 bg-gray-200 rounded cursor-pointer hover:bg-gray-400"
                  onClick={() => setEditarTabla(!editarTabla)}
                >
                  Guardar cambios
                </button>
              )}
              {editarTabla ? (
                <button
                  className="px-2 text-white bg-indigo-900 rounded cursor-pointer hover:bg-indigo-600"
                  onClick={() =>
                    setTabla([
                      ...Tabla,
                      {
                        TipoDePago: "",
                        Programa: "",
                        CostoDePrograma: 0,
                        NombreProveedor: "",
                        CFDI: "",
                        RFC: "",
                        Subtotal: 0,
                        IVA: 0,
                        Retencion: 0,
                        MontoTotalFacturas: 0,
                        TotalNegociado: 0,
                        Comision: 0,
                        TotalAPagar: 2150.6,
                        Titular: "Arturo Hernández",
                        Tarjeta: "",
                        Parentesco: "",
                      },
                    ])
                  }
                >
                  Añadir prima
                </button>
              ) : (
                <button
                  className="px-2 button-primary"
                  onClick={() => setEditarTabla(!editarTabla)}
                >
                  Editar tabla
                </button>
              )}
            </div>
            {/* !SECTION Editar tabla  */}
          </div>
          {/* !SECTION Table header */}

          {/* SECTION Tabla */}
          <div className="overflow-x-auto">
            <div className="table text-sm shadow-sm table-fixed">
              <div className="table-header-group font-bold text-center text-gray-100 bg-indigo-900 border-b-2">
                <div className="table-cell p-2">Costo de programa</div>
                <div className="table-cell p-2 w-44">Nombre del proveedor</div>
                <div className="table-cell w-24 p-2">CFDI</div>
                <div className="table-cell w-24 p-2">RFC</div>
                <div className="table-cell w-24 p-2">Subtotal</div>
                <div className="table-cell w-20 p-2">IVA</div>
                <div className="table-cell p-2">Retención</div>
                <div className="table-cell w-24 p-2">Monto facturas</div>
                <div className="table-cell p-2">Total negociado</div>
                <div className="table-cell p-2">Comisión</div>
                <div className="table-cell p-2">Total a pagar</div>
                <div className="table-cell w-32 p-2">Titular</div>
                <div className="table-cell p-2 w-52">Tarjeta</div>
                <div className="table-cell w-32 p-2">Parentesco</div>
              </div>
              <div className="table-row-group divide-y">
                {Tabla.map((row, i) => (
                  <div
                    className={`table-row text-center text-gray-800 align-middle divide-y group ${
                      i % 2 === 0 ? `bg-white ` : `bg-gray-100`
                    }`}
                    key={i}
                  >
                    {/* CostoDePrograma */}
                    <div className="table-cell p-2 align-middle group-hover:bg-gray-200">
                      {formatMoney(row.CostoDePrograma)}
                    </div>

                    {/* NombreProveedor */}
                    <div className="table-cell p-2 align-middle group-hover:bg-gray-200">
                      {editarTabla ? (
                        <input
                          type="text"
                          className="w-40"
                          defaultValue={row.NombreProveedor}
                          onChange={(e) =>
                            (row.NombreProveedor = e.target.value)
                          }
                        />
                      ) : (
                        row.NombreProveedor
                      )}
                    </div>

                    {/* CFDI */}
                    <div className="table-cell p-2 align-middle group-hover:bg-gray-200">
                      {editarTabla ? (
                        <input
                          type="text"
                          className="w-24"
                          defaultValue={row.CFDI}
                          onChange={(e) => (row.CFDI = e.target.value)}
                        />
                      ) : (
                        row.CFDI
                      )}
                    </div>

                    {/* RFC */}
                    <div className="table-cell p-2 align-middle group-hover:bg-gray-200">
                      {editarTabla ? (
                        <input
                          type="text"
                          className="w-32"
                          defaultValue={row.RFC}
                          onChange={(e) => (row.RFC = e.target.value)}
                        />
                      ) : (
                        row.RFC
                      )}
                    </div>

                    {/* Subtotal */}
                    <div className="table-cell p-2 align-middle group-hover:bg-gray-200">
                      {formatMoney(row.Subtotal)}
                    </div>

                    {/* IVA */}
                    <div className="table-cell p-2 align-middle group-hover:bg-gray-200">
                      {editarTabla ? (
                        <select
                          onChange={(e) => (row.IVA = parseInt(e.target.value))}
                          defaultValue={row.Parentesco}
                        >
                          <option value={16}>16%</option>
                          <option value={8}>8%</option>
                        </select>
                      ) : (
                        formatMoney(row.IVA)
                      )}
                    </div>

                    {/* Retencion */}
                    <div className="table-cell p-2 align-middle group-hover:bg-gray-200">
                      {formatMoney(row.Retencion)}
                    </div>

                    {/* MontoTotalFacturas */}
                    <div className="table-cell p-2 align-middle group-hover:bg-gray-200">
                      {editarTabla ? (
                        <input
                          type="number"
                          className="w-24"
                          defaultValue={row.MontoTotalFacturas}
                          onChange={(e) =>
                            (row.MontoTotalFacturas = parseFloat(
                              e.target.value
                            ))
                          }
                        />
                      ) : (
                        formatMoney(row.MontoTotalFacturas)
                      )}
                    </div>

                    {/* TotalNegociado */}
                    <div className="table-cell p-2 align-middle group-hover:bg-gray-200">
                      {editarTabla ? (
                        <input
                          type="number"
                          className="w-24"
                          defaultValue={row.TotalNegociado}
                          onChange={(e) =>
                            (row.TotalNegociado = parseFloat(e.target.value))
                          }
                        />
                      ) : (
                        formatMoney(row.TotalNegociado)
                      )}
                    </div>

                    {/* Comision */}
                    <div className="table-cell p-2 align-middle group-hover:bg-gray-200">
                      {editarTabla ? (
                        <input
                          type="text"
                          className="w-32"
                          defaultValue={row.Comision}
                          onChange={(e) =>
                            (row.Comision = parseFloat(e.target.value))
                          }
                        />
                      ) : (
                        formatMoney(row.Comision)
                      )}
                    </div>

                    {/* Total a pagar */}
                    <div className="table-cell p-2 align-middle group-hover:bg-gray-200">
                      {formatMoney(row.TotalAPagar)}
                    </div>

                    {/* Titular */}
                    <div className="table-cell p-2 align-middle group-hover:bg-gray-200">
                      {row.Titular}
                    </div>

                    {/* Tarjeta */}
                    <div className="table-cell p-2 align-middle group-hover:bg-gray-200">
                      {editarTabla ? (
                        <input
                          type="number"
                          defaultValue={row.Tarjeta}
                          onChange={(e) => (row.Tarjeta = e.target.value)}
                        />
                      ) : (
                        formatCreditCard(row.Tarjeta)
                      )}
                    </div>

                    {/* Parentesco */}
                    <div className="table-cell p-2 align-middle group-hover:bg-gray-200">
                      {editarTabla ? (
                        <select
                          onChange={(e) => (row.Parentesco = e.target.value)}
                          defaultValue={row.Parentesco}
                        >
                          <option value="Titular">Titular</option>
                          <option value="Esposo(a)">Esposo(a)</option>
                          <option value="Cónyugue">Cónyugue</option>
                          <option value="Papá">Papá</option>
                          <option value="Mamá">Mamá</option>
                          <option value="Hermano(a)">Hermano(a)</option>
                          <option value="Hijo(a)">Hijo(a)</option>
                        </select>
                      ) : (
                        row.Parentesco
                      )}
                    </div>
                  </div>
                ))}
                <div className="table-cell py-2 align-middle"></div>
                <div className="table-cell py-2 align-middle"></div>
                <div className="table-cell py-2 align-middle"></div>
                <div className="table-cell py-2 align-middle"></div>
                <div className="table-cell py-2 align-middle"></div>
                <div className="table-cell py-2 align-middle"></div>
                <div className="table-cell py-2 align-middle"></div>
                <div className="table-cell py-2 align-middle"></div>
                <div className="table-cell py-2 align-middle"></div>
                <div className="table-cell py-2 text-right align-middle">
                  Total:{" "}
                </div>
                <div className="table-cell py-2 text-center align-middle">
                  <span className="px-2 py-1 text-green-500 bg-green-100 rounded-full">
                    {formatMoney(3500.34)}
                  </span>
                </div>
                <div className="table-cell py-2 align-middle"></div>
                <div className="table-cell py-2 align-middle"></div>
                <div className="table-cell py-2 text-center align-middle"></div>
                <div className="table-cell py-2 text-center align-middle"></div>
              </div>
            </div>
          </div>
          {/* !SECTION Tabla */}

          <div className="mx-auto my-8 overflow-hidden rounded-lg cursor-pointer">
            <div className="cursor-pointer md:flex">
              <div className="w-full p-3">
                <div className="relative flex items-center justify-center h-32 bg-gray-100 border-2 border-blue-700 border-dashed rounded-lg hover:bg-indigo-300">
                  <div className="absolute">
                    <div className="flex flex-col items-center">
                      <i className="text-3xl bi bi-files"></i>
                      <span className="block font-normal">Añade archivo.</span>
                    </div>
                  </div>
                  <input
                    type="file"
                    className="w-full h-full opacity-0 cursor-pointer hover:bg-indigo-800"
                    name="Archivos"
                    multiple
                  />
                </div>
              </div>
            </div>
          </div>

          <button
            className="px-2 mx-auto my-4 button-primary"
            onClick={() => setShowModal(!showModal)}
          >
            Autorizar parentesco
          </button>
          <div className="flex flex-col gap-8">
            {" "}
            <div className="flex flex-row items-stretch w-full gap-8">
              <div className="flex flex-col items-stretch">
                <label
                  htmlFor="AutorizaPago"
                  className="block mb-2 font-bold text-gray-700"
                >
                  Quién autoriza pago
                </label>
                <input
                  type="text"
                  name="AutorizaPago"
                  id="FechaSolicitud"
                  className="input"
                  disabled
                  value="Tú"
                  placeholder="Escribe"
                />
              </div>
              <div className="flex flex-col items-stretch">
                <label
                  htmlFor="MotivoDePago"
                  className="block mb-2 font-bold text-gray-700"
                >
                  Motivo de pago
                </label>
                <input
                  type="text"
                  name="MotivoDePago"
                  id="FechaSolicitud"
                  className="input"
                  placeholder="Escribe"
                />
              </div>
            </div>
            <div className="flex flex-col items-stretch">
              <label
                htmlFor="Observaciones"
                className="block mb-2 font-bold text-gray-700"
              >
                Observaciones
              </label>
              <textarea className="border rounded-md resize input"></textarea>
            </div>
          </div>
          {!pagoAutorizado && (
            <button
              className="px-2 mx-auto my-4 button-primary"
              onClick={() => handleAutorizarPago()}
            >
              Autorizar pago
            </button>
          )}
        </div>
      )}
      {/* !SECTION Tabla de pago */}

      {/* SECTION Información de pago de pago */}
      {pagoAutorizado && (
        <React.Fragment>
          <div className="py-4">
            <div className="flex flex-row justify-between text-xl font-bold text-gray-800">
              <div>
                <i className="pb-1 pr-2 bi bi-receipt"></i>
                Información de pago de pago
              </div>
              <div className="text-xl rounded cursor-pointer hover:bg-indigo-100">
                <i
                  className={`bi bi-chevron-${
                    showInfoDePago ? `contract` : `expand`
                  }`}
                  onClick={() => setShowInfoDePago(!showInfoDePago)}
                ></i>
              </div>
            </div>
            <hr className="bg-gray-300" />
          </div>

          {showInfoDePago && (
            <React.Fragment>
              {comprobanteDePago ? (
                <div className="flex flex-col">
                  <div className="flex flex-row gap-8 pb-8">
                    <div className="flex flex-col items-stretch w-1/3">
                      <label
                        htmlFor="TipoRetroalimentacion"
                        className="block mb-2 font-bold text-gray-700"
                      >
                        Fecha de aplicación
                      </label>
                      <input
                        type="text"
                        name="FechaSolicitud"
                        id="FechaSolicitud"
                        className="input"
                        disabled
                        value="27-02-2021 10:50"
                      />
                    </div>
                    <label
                      htmlFor="ProcedeQueja"
                      className="flex flex-row items-center gap-4 pt-8 input-label"
                    >
                      <input
                        type="checkbox"
                        name="ProcedeQueja"
                        className="input"
                        checked={true}
                        disabled
                      />
                      Pago aplicado
                    </label>
                  </div>
                  <p className="font-bold text-center">
                    Indicador de aplicación de pago
                  </p>
                  <div className="py-2 text-center">
                    <div className="relative">
                      <div className="flex h-2 my-2 overflow-hidden text-xs bg-gray-200 rounded">
                        <div
                          style={{
                            width: `${40}%`,
                          }}
                          className={`flex flex-col justify-center text-center text-white rounded-full shadow-none whitespace-nowrap ${TiempoDelProveedor(
                            40
                          )}`}
                        ></div>
                      </div>
                    </div>
                  </div>
                  <p className="text-center">A tiempo</p>
                </div>
              ) : (
                <p className="py-8 text-center">
                  El cliente aún no ha enviado un comprobante de pago.
                </p>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default AplicacionDelPago;
