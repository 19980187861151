import CASCForm from "../components/casc/bodies/1";

const NuevoCASC = (): JSX.Element => {
  return (
    <div className="w-full p-4 bg-white rounded shadow">
      <h1 className="pb-4 text-xl font-bold text-indigo-800">
        Generar nuevo CASC
      </h1>
      <CASCForm />
    </div>
  );
};

export default NuevoCASC;
